import { createTimeLine, store } from "@/views/store/store"
import { set, ref as dbRef, getDatabase, get, update, remove } from "firebase/database"
import { confirmAction } from "@/views/store/confirmAction"
import { WalletTransaction } from "../Customers/updateWallet"
import { formatPrice, sum } from "@/util"
import dayjs from "dayjs"
import { projectFunctions } from "@/firebase/config";
import { httpsCallable } from "firebase/functions"
import { mealpointTransaction } from "@/util/mealpointrefund"
import { useToast } from "vue-toast-notification"
import { orderUpdateMealInventory } from "../../../store/store"
import { refundReferralUsed, restoreReferralUsed } from '@/util/returnReferrralAmount'
const saveOrderHistory = httpsCallable(projectFunctions, "saveOrderHistory");

export const cancelOrder = async (order) => {


    // const confirmCanc
    try {
        const user = {
            email: store.state.user.email,
            first_name: store.state.user.first_name,
            last_name: store.state.user.last_name,
            uid: store.state.user.uid,
        }
        await set(dbRef(getDatabase(), `/orders/${order.id}/status/Cancelled`), {
            state: true,
            time: JSON.stringify(new Date().toISOString())
        })

        await remove(dbRef(getDatabase(), `/orders/${order.id}/isProcessing`))
        if (order?.status?.Assembled?.state) await orderUpdateMealInventory({ order, user, reason: `${order?.id} cancelled`, type: 'add' })
        saveOrderHistory({ userId: order?.customer?.id });
        refundReferralUsed(order)
        await createTimeLine({
            orderId: order?.id,
            user_type: 'admin',
            uid: store?.state.user.uid,
            name: store?.state.user.first_name + " " + store?.state.user.last_name,
            message: `cancelled this order `,
        })

    } catch (error) {
        console.log(error)

    }
}

export const restoreCancelledOrder = async (order, type, onSuccess) => {
    const toast = useToast({ position: 'top-right' })
    let isPaid = !order?.status?.AwaitingPayment?.state

    const fn = async () => {
        try {
            // await set(dbRef(getDatabase(), `/orders/${order.id}/status/Cancelled`), {
            //     state: false,
            //     time: new Date().toISOString()
            // })
            // await set(dbRef(getDatabase(), `/orders/${order.id}/status/Confirmed`), {
            //     state: true,
            //     time: new Date().toISOString()
            // })
            toast.info("Please wait while order is restored")
            const time = JSON.stringify(new Date().toISOString())
            const transportStatus = order?.locationType === 'Delivery' ?
                {
                    Dispatched: { state: false, time: '' },
                    Arrived: { state: false, time: '' },
                    Delivered: { state: false, time: '' },
                } : {
                    Pickup: { state: false, time: '' },
                }
            await update(dbRef(getDatabase(), `/orders/${order.id}/status/`), {
                Confirmed: { state: true, time },
                Cancelled: { state: false, time },
                Prepared: { state: false, time: '' },
                Assembled: { state: false, time: '' },
                ...transportStatus
            })
            await update(dbRef(getDatabase(), `/orders/${order.id}/`), {
                isProcessing: order?.customer?.id,
                date: JSON.stringify(
                    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                ),
                courier: ""
            })
            if (!order?.placed_date) await set(dbRef(getDatabase(), `/orders/${order.id}/placed_date`), order?.date)

            // await set(dbRef(getDatabase(), `/orders/${order.id}/isProcessing`), order?.customer?.id)
            // await set(dbRef(getDatabase(), `/orders/${order.id}/date`), )

            const items = order?.items
            for (let i = 0; i < items.length; i++) {
                // const item = items[i];
                await update(dbRef(getDatabase(), `/orders/${order?.id}/items/${i}/`), {
                    prepared: 0,
                    assembled: false
                })

            }

            const mealPoint = sum(order?.items?.filter(item => item?.point).map(item => item.point))
            if (mealPoint) await mealpointTransaction(order, mealPoint, null, 'subtract')
            restoreReferralUsed(order)
            let chargeMessage = 'no charge made'
            if (type == 'wallet') chargeMessage = ` and charged (${formatPrice(order?.total_price)})  via wallet deduction`
            if (type == 'bank alert') chargeMessage = `and charged (${formatPrice(order?.total_price)}) via manual confirmation`
            await createTimeLine({
                orderId: order?.id,
                user_type: 'admin',
                uid: store?.state.user.uid,
                name: store?.state.user.first_name + " " + store?.state.user.last_name,
                message: `restored this order from cancelled ${chargeMessage}`,
            })
            onSuccess()
        } catch (error) {
            console.log(error)

        }
    }

    if (isPaid && type == 'wallet') {

        const walletFn = async () => {
            const user = (await get(dbRef(getDatabase(), `/users/${order?.customer?.id}`))).val()
            const wallet = new WalletTransaction(user)
            await wallet.withdrawFromWallet(order?.total_price, 'cancelled order restore', () => fn())
        }

        confirmAction.commit('toggleModal', {
            onProceed: () => walletFn(),
            confirmText: 'WALLET',
        })
    } else {
        console.log('============+=========')
        confirmAction.commit('toggleModal', {
            onProceed: () => fn(),
            confirmText: 'BANK ALERT',
        })

    }


}
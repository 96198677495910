

import {  getToken } from "firebase/messaging"; 
import { messaging } from "./firebase/config";

export function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        console.log(permission)
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        }else{
            
        }

    })

}

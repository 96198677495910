
// let no_of_customers_by_restaurant = 0;
// let theOrders =  []
// todaysOrders.forEach((order) => {
//   const restaurants_in_order = order?.items?.map(
//     (item) => item?.resturant
//   );
//   if (restaurants_in_order?.includes("Crêpe & Co Café")) {
//     no_of_customers_by_restaurant++;
//     theOrders.push(order?.id)
//   }
// });
// console.log({no_of_customers_by_restaurant,theOrders})

export const ordersByItem = (ordersSelectedFrom, itemName) => {
      let orders = []

      ordersSelectedFrom.forEach(element => {
            const exists = (element?.items?.filter(item => item?.name?.toLowerCase().includes(itemName?.toLowerCase()))) || []
            if (exists.length) orders.push(element)
      });

      return orders

}

export const ordersByRestaurant = (ordersSelectedFrom, resturant_name) => {
      let orders = []
      // console.log({ resturant_name })
      ordersSelectedFrom.forEach(element => {
            const exists = (element?.items?.filter(item => item?.resturant?.toLowerCase() === resturant_name?.toLowerCase())) || []
            if (exists.length) orders.push(element)
      });

      return orders

}

export const ordersByCustomer = ({ orders = [], customer, customer_uid, email }) => {
      return orders.filter(order => {
            return order.customer?.email?.toLowerCase()?.includes(email?.toLowerCase())
      })

}
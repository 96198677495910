<template>
  <div class="w-full h-full top_selling_item !relative">
    <nav>
      <div class="left">
        <Text :weight="600" :size="18">Top Selling Meals</Text>
      </div>
      <div class="rightttt">
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </div>
    </nav>
    <main>
      <div
        v-if="!meals.length"
        class="h-[100%] flex items-center justify-center"
      >
        <Text :weight="700" :size="24"> No orders yets</Text>
      </div>
      <div
        class="card"
        v-for="(meal, i) in meals"
        :key="meal.name"
      >
        <div class="left gap-[19px]">
          <div class="number h-full flex items-center">#{{ i + 1 }}</div>
          <div class="center flex py-2 flex-col justify-between h-full w-full">
            <Text :size="16" :weight="600" class="top">
              {{ meal.name }}
            </Text>
            <div class="bottom w-full flex justify-between">
              <!-- <Text :weight="500" :size="14"> Orders : {{ meal.number_of_orders }} </Text> -->
              <Text :weight="500" :size="14">
                Ordered {{ meal.quantity }}x</Text
              >
            </div>
          </div>
        </div>
        <img :src="meal.img" alt="" />
      </div>

 
    </main>
  </div>
</template>

<script>
import Text from "@/components/Text.vue";
import TimeDropDownVue from "./TimeDropDown.vue";
import { watch, watchEffect } from "vue";
import { ref } from "vue";
import { getOrdersByTimeFrame } from "@/util";
export default {
  components: { Text, TimeDropDown: TimeDropDownVue },
  setup() {
    const timeFrame = ref({});
    const meals = ref([]);
    watchEffect(async() => {
      const { start, end } = timeFrame.value;
      const ordersInTimeFrame = await getOrdersByTimeFrame(start, end);
      let top_selling_meals = [];

      if (ordersInTimeFrame.length) {
        const itemsInTimeFrame = ordersInTimeFrame.map((x) => x?.items).flat(1)?.filter(x => !(x?.cancelled || x?.canceled));
        // console.log(ordersInTimeFrame.map((x) => x?.items).flat(1))
        const meals = new Set();
        itemsInTimeFrame.map((x) => meals.add(x?.name)); //unique meals
        Array.from(meals).map((meal, i) => {
          let gather_meals = itemsInTimeFrame.filter((x) => x?.name === meal);
          if (gather_meals.length) {
            top_selling_meals[i] = {
              name: gather_meals[0].name,
              price: gather_meals[0].amount,
              img: gather_meals[0].img,
              quantity: gather_meals
                .map((x) => x.quantity)
                .reduce((a, b) => a + b),
              number_of_orders: gather_meals.length,
            };
          }
        });
      } else {
        top_selling_meals = [];
      }

      meals.value = top_selling_meals.sort((a, b) => b.quantity - a.quantity).splice(0, 10);
    });

    // watchEffect(()=>{
    //   top_selling_meals.sort((a, b) => b.number_of_orders - a.number_of_orders)
    // })
    return {
      timeFrame,
      meals,
    };
  },
};
</script>

<style lang="scss" >
.top_selling_item {
  font-family: Work Sans;
  height: 100%;
  padding: 33px 35px;
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: absolute;top: 10px;width: 100%;
    .left {
      // top: 30px;
      // position: absolute;
      // left: 10px;
    }
    left: 10px;
    .rightttt {
      // top: 30px;
      // position: absolute;
      // // width: 75px;
      // right: 10px;
    }
  }
  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding-right: 4px;
    margin-top: 30px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      flex-direction: row;
      height: 1;
      border: none;
      img {
        width: 64px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        height: 65px;
        // align-self: flex-end;
        display: block;
      }
      .left {
        height: 100%;
        display: flex;
        gap: 13px;
        align-items: center;
        width: fit-content;
      }
    }
  }
}
</style>
<template>
  <div class="px-[40px] bg-light w-full h-full mb-4">
    <div class="d-flex justify-content-between orderClass p-3 w-full">
      <div class="w-full">
        <div class="flex w-full items-center justify-between">
          <h1>
            <Text :size="27" :weight="700"> Fetch orders by date</Text>
          </h1>
          <time-drop-down :getDate="(e) => (timeFrame = e)" />
        </div>

      </div>
    </div>
    <section class="orderList !p-0 !bg-white">
      <div>
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header"></th>
              <th class="table-header">Order ID</th>
              <th class="table-header">Date</th>
              <th class="table-header">Customer Name</th>
              <th class="table-header">Service</th>
              <th class="table-header">Courier</th>
              <th class="table-header">Status</th>
              <th class="table-header">Time Left</th>
              <th class="table-header">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in paginatedOrders"
              :key="index"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">{{ index + 1 }}</td>
              <td class="table-data">{{ getOrderID(order) }}</td>
              <td class="table-data">{{ formatDate(order.date) }}</td>
              <td class="table-data">
                {{ order.customer.firstName + " " + order.customer.lastName }}
              </td>
              <td class="table-data">{{ order.locationType }}</td>
              <td class="table-data" v-if="order.locationType === 'Delivery'">
                {{ order.courier.name ? order.courier.name : "Unassigned" }}
              </td>
              <td class="table-data" v-else>N/A</td>
              <td class="table-data">
                <Status
                  :items="order.items"
                  :status="order.status"
                  :order="order"
                />
              </td>

              <td class="table-data">{{ diffByStatus(order) }}</td>

              <td class="table-data">
                <a
                  @click="router.push(`/operator/orders/${order?.id}`)"
                  :href="`/operator/orders/${order?.id}`"
                  class="action-link !underline"
                  >View</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
      </div>
    </section>
  </div>
</template>

<script setup>
import {
  getOrderID,
  formatDate,
  diffByStatus,
  getOrdersByTimeFrame,
} from "@/util";
import { ref, watchEffect , computed} from "vue";
import Status from '@/components/Status.vue'

import { useRouter } from "vue-router";
import TimeDropDown from "./TimeDropDown.vue";

const router = useRouter();
const orders = ref([]);
const timeFrame = ref([]);

watchEffect(async () => {
  orders.value = await getOrdersByTimeFrame(
    timeFrame.value?.start,
    timeFrame.value?.end
  );
});

   // Pagination
   const ordersPerPage = 15;
    const currentPage = ref(1);
    const totalPages = computed(() =>
      Math.ceil(orders.value.length / ordersPerPage)
    );

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };


    const paginatedOrders = computed(() => {
      const startIndex = (currentPage.value - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;
      return orders.value.slice(startIndex, endIndex);
    });

    console.log(paginatedOrders.value,orders.value)



</script>

<style lang="scss" scoped>
</style>
<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full">
      <!-- <TopNav /> -->
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> {{ "Bug reports" }}</Text>
              <!-- <span class="capitalize">{{ selectId }}</span> -->
            </h1>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">Order ID</th>
                <th class="table-header">Date</th>
                <th class="table-header">Customer</th>
                <th class="table-header">App version</th>
                <th class="table-header">App total</th>
                <th class="table-header">Calculated total</th>
                <!-- <th class="table-header">Reason</th> -->
                <!-- <th class="table-header">Admin</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bugReport, index) in orderTotalBugs?.toReversed()"
                :key="bugReport?.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td>
                <td class="table-data">
                  <OrderID :id="bugReport?.order?.id" />
                </td>
                <td class="table-data">
                  {{
                    dayjs(bugReport?.order?.date?.replaceAll('"', "")).format(
                      "MMM DD. hh:mmA "
                    )
                  }}
                </td>
                <td class="table-data">
                  <CustomerName :customer-id="bugReport?.order?.customer?.id" />
                </td>
                <td class="table-data">
                  {{ bugReport?.order?.appVersion || bugReport?.appVersion }}
                </td>

                <td class="table-data">
                  {{ formatPrice(bugReport?.order?.total_price) }}
                </td>

                <!-- <td class="table-data">{{ bugReport.message }}</td> -->

                <td class="table-data">
                  {{
                    formatPrice(
                      checkTotal(bugReport?.order).details.calculated_total
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>
  </layout>
</template>
            
          <script setup>
import { ref, computed, watchEffect } from "vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import {
  sum,
  formatDate,
  getUeOrderId,
  // diffByStatus,
  formatPrice,
  checkTotal,
} from "@/util";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import {
  set,
  ref as dbRef,
  getDatabase,
  get,
  push,
  query,
} from "firebase/database";
import dayjs from "dayjs";
import CustomerName from "@/components/CustomerName.vue";
import OrderID from "@/components/OrderID.vue";

const orderTotalBugs = ref([]);

watchEffect(async () => {
  try {
    console.log('00--00')
    const quer = await get(dbRef(getDatabase(), `bug_reports/order_total`));
    console.log('00')
    orderTotalBugs.value = Object.values(quer?.val() || {});
    console.log(orderTotalBugs.value);
  } catch (error) {
    console.log(error,'----');
  }
});

// Pagination
const ordersPerPage = 5;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(orderTotalBugs.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedOrderTotalBugs = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return orderTotalBugs.value.slice(startIndex, endIndex);
});
console.log(
  { paginatedOrderTotalBugs: paginatedOrderTotalBugs.value },
  orderTotalBugs.value
);
</script>

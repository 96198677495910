import { get, getDatabase, ref, set, update } from "firebase/database";

export const refundReferralUsed = async (order) => {
    const userId = order?.customer?.id
    try {
        if (order?.referralUsed) {
            const userReferralRef = ref(getDatabase(), `users/${userId}/referral`)
            const userReferral = (await get(userReferralRef)).val() || {}
            const updatedReferral = {
                ...userReferral,
                amount:
                    Number(userReferral?.amount) +
                    Number(order?.referralUsed),
                amount_used:
                    Number(userReferral?.amount_used) -
                    Number(order?.referralUsed),
            }
            console.log(updatedReferral)
            await set(userReferralRef, updatedReferral)
        }
        return;
    } catch (error) {
        console.log(error)
    }

}
export const restoreReferralUsed = async (order) => {
    const userId = order?.customer?.id
    try {
        if (order?.referralUsed) {
            const userReferralRef = ref(getDatabase(), `users/${userId}/referral`)
            const userReferral = (await get(userReferralRef)).val() || {}
            const updatedReferral = {
                ...userReferral,
                amount:
                    Number(userReferral?.amount) -
                    Number(order?.referralUsed),
                amount_used:
                    Number(userReferral?.amount_used) +
                    Number(order?.referralUsed),
            }
            console.log(updatedReferral)
            await set(userReferralRef, updatedReferral)
        }
        return;
    } catch (error) {
        console.log(error)
    }

}
<template>
  <!-- <div> -->

  <v-tooltip
    v-if="hasBug"
    location="top center"
    origin="top start"
    no-click-animation
  >
    <template v-slot:activator="{ props }">
      <span v-bind="props" class="cursor-pointer">🔴</span>
    </template>

    <div class="flex flex-col gap-0">
      <div>{{ repeatedTimeline ? "Repeated timeline" : "" }}</div>
      <div>{{ doubleMealPoint ? "Double meal point" : "" }}</div>
      <div>{{ orderTotal ? "Order total " : "" }}</div>
    </div>
  </v-tooltip>
  <!-- </div> -->
</template>

<script setup>
import { checkRepeatedTimeline, checkTotal } from "@/util";
import dayjs from "dayjs";
import { set, ref as dbRef, getDatabase, push, get } from "firebase/database";
import { computed, ref, watchEffect } from "vue";

const props = defineProps({
  order: Object,
});

const repeatedTimeline = ref(false);
const orderTotal = computed(() => {
  const mealPBug =
    (checkTotal(props.order).state && checkTotal(props.order).isNormal) ||
    checkTotal(props.order).hasMBP ||
    checkTotal(props.order).promoUsed;
  return !mealPBug;
});
const doubleMealPoint = ref(false);

watchEffect(() => {
  repeatedTimeline.value = false;
  const mealPointSaved = Object.values(props.order?.mealPointSaved || {});
  if (mealPointSaved.length) doubleMealPoint.value = mealPointSaved.length != 1;
});
const hasBug = computed(() => {
  return repeatedTimeline.value || orderTotal.value || doubleMealPoint.value;
});
const saveBugReport = async () => {
  if (!props.order?.bugReport) {
    try {
      set(dbRef(getDatabase(), `orders/${props.order?.id}/bugReport`), {
        repeatedTimeline: repeatedTimeline.value,
        orderTotal: orderTotal.value,
        doubleMealPoint: doubleMealPoint.value,
        anyTrue: hasBug.value,
      });
      if (orderTotal.value) { 
        const appVersion = (
          await get(
            dbRef(
              getDatabase(),
              `users/${props?.order?.customer?.id}/appVersion`
            )
          )
        ).val();
        set(dbRef(getDatabase(), `bug_reports/order_total/${props.order?.id}`), {
          // type: "order-total",
          appVersion,
          order: props.order,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
};
if (hasBug.value) saveBugReport();
</script>

<style lang="scss" scoped>
</style>
<template>
  <Text :size="17" :weight="700">
    Referrals ({{ props.customer?.referral?.code }})
  </Text>
  <div class="flex flex-wrap justify-between gap-1 w-full">
    <!-- <Text :size="14">
      Total referrals ({{ props.customer?.referral?.history?.length || 0 }})
    </Text> -->
    <div class="w-[180px]">
      <Text :color="'grey'" class="uppercase" :size="14">
        TOTAL CREDIT balance
      </Text>
      <Text :color="'black'" weight="700" :size="20"
        >({{ formatPrice(props.customer?.referral?.amount) }})</Text
      >
    </div>
    <div class="w-[180px]">
      <Text :color="'grey'" class="uppercase" :size="14">
        TOTAL CREDIT earned
      </Text>
      <Text :color="'black'" weight="700" :size="20"
        >({{ formatPrice(props.customer?.referral?.amount + props.customer?.referral?.amount_used) }})</Text
      >
    </div>
    <div class="w-[180px]">
      <Text :color="'grey'" class="uppercase" :size="14">
        TOTAL CREDIT used
      </Text>
      <Text :color="'black'" weight="700" :size="20"
        >({{ formatPrice(props.customer?.referral?.amount_used) }})</Text
      >
    </div>

    <!-- <time-drop-down :getDate="(e) => (timeFrame = e)" /> -->
  </div>
  <hr />
  <div class="w-full flex justify-between my-3">
    <div class="w-[200px]">
      <Text :size="14"> Successful Referrals </Text>
      <Text
        class="rounded text-white bg-danger h-[30px] w-[40px] text-center"
        weight="700"
        :size="20"
        >{{
          props.customer?.referral?.history?.filter(
            (x) => x?.status === "Successful"
          )?.length || 0
        }}
      </Text>
    </div>
    <div class="w-[200px] text-right ">
      <Text :size="14" class="floa"> Pending Referrals </Text>
      <Text
        class="rounded text-white float-right bg-danger h-[30px] w-[40px] text-center"
        weight="700"
        :size="20"
        >{{
          props.customer?.referral?.history?.filter(
            (x) => x?.status === "Pending"
          )?.length || 0
        }}
      </Text>
    </div>
  </div>
  <hr />
  <table class="order-table">
    <thead>
      <tr>
        <th class="table-header"></th>
        <th class="table-header">Date</th>
        <th class="table-header">Friend</th>
        <th class="table-header">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(referral, index) in props.customer?.referral?.history"
        :key="referral.uid"
        class="table-row"
        :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
      >
        <td class="table-data">
          {{ index + 1 }}
        </td>
        <td class="table-data">
          {{
            dayjs(referral?.created?.replaceAll('"', "")).format(" DD MMM YYYY")
          }}
        </td>

        <td class="table-data">
          <customer-name :id_key="'id'" :customerId="referral?.uid" />
        </td>
        <td class="table-data">
          <div
            class="rounded text-center p-2 w-fit"
            :class="
              referral?.status === 'Successful' ? 'text-success' : 'text-info'
            "
          >
            {{ referral?.status }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <Pagination
    class="py-3"
    :currentPage="currentPage"
    :totalPages="totalPages"
    @page-change="goToPage"
  /> -->
</template>
  
  <script setup>
import Pagination from "@/components/Pagination.vue";
import Text from "@/components/Text.vue";
import { computed, watchEffect } from "vue";
import CustomerName from "@/components/CustomerName.vue";
import { formatPrice } from "@/util";

import { ref } from "vue";
import dayjs from "dayjs";
const referral = ref({});
const props = defineProps({
  customer: Object,
});

watchEffect(async () => {
  //  referral.value =(props.customer?.referral)
});
</script>
  
  <style lang="scss" scoped>
</style>

// import { useToast } from 'vue-toast-notification';
import { createStore } from "vuex"
// import { store } from "./store";
// import { extra_permissions_keys } from '../main/Operator/Accounts/extra_permissions';

// const toast = useToast({ position: 'top-right' })
export const loadingScreen = createStore({
    state() {
        return {
            loading: false,
            message: '',


        }
    },
    mutations: {
        openLoadingScreen: (state, payload) => {
            state.loading = payload.loading
            state.errorMessage =  payload.message
        },

    },
    actions: {
    },
    // plugins: [createPersistedState()]
})
<template>
  <div v-if="loadingScreen.state.loading" class="loadingScreen">
    <div class="spinner"></div>
    {{ loadingScreen.state.message }}
  </div>
</template>

<script setup>
import { onMounted, onBeforeMount } from "vue";
import { loadingScreen } from "./views/store/loadingScreen";
// import { useEventListener } from "@vueuse/core";
onBeforeMount(() => {
  if (loadingScreen.state.loading) {
    window.removeEventListener("beforeunload", (e) => {
      e.preventDefault();
    });
  }
});
onMounted(() => {
  if (loadingScreen.state.loading) {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
      e.returnValue = true;
    });
  }
});
</script>


<style lang="scss" scoped>
.loadingScreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: black;
  opacity: 0.4;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  gap: 20px;
  font-weight: 600;
  z-index: 10000;
}
.spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <v-dialog v-model="option_meals_to_reorder.option_items">
    <div class="mx-auto rounded bg-white p-2 w-[300px]">
      <!-- {{ option_meals_to_reorder }} -->
      <Text :size="14" weight="600" class="pb-2">
        Reorder {{ option_meals_to_reorder?.option }} items in
        {{ option_meals_to_reorder?.meal?.name }}</Text
      >
      <!-- <div
        v-for="item in option_meals_to_reorder?.option_items"
        :key="item.name"
        class="border mb-1 rounded p-1"
      >
        {{ item?.name }}
      </div> -->

      <draggable v-model="option_meals_to_reorder.option_items">
        <template #item="{ element: item }">
          <div class="border mb-1 rounded p-1 cursor-move" :key="item?.name">
            <Text> {{ item?.name }}</Text>
          </div>
        </template>
      </draggable>

      <div class="flex justify-center mt-2 gap-2">
        <button
          class="bg-white rounded p-2 border"
          @click="
            () => {
              option_meals_to_reorder = {};
            }
          "
        >
          Cancel
        </button>
        <ue-button @click="saveOptionMealsPosition">Save</ue-button>
      </div>
    </div>
  </v-dialog>
  <div class="bg-white p-2 mb-3 w-100 rounded">
    <div class="flex justify-between mb-3">
      <Text :size="20" weight="600"> Update meal options order</Text>
      <div class="flex gap-2"></div>
    </div>
    <div
      class="flex flex-wrap gap-3 items-center relative"
      style="min-width: 100% !important"
    >
      <div
        v-for="meal in mealsWithOptionsOrder"
        :key="meal.name"
        class="border rounded p-2"
      >
        <div class="w-[100%] flex gap-2 justify-between">
          <Text weight="600"> {{ meal?.name }}</Text>
          <div class="flex gap-3">
            <button
              v-if="current_?.name == meal?.name"
              @click="
                () => {
                  current_ = '';
                  current_rearrange = '';
                }
              "
              class="underline cursor-pointer"
            >
              Cancel
            </button>
            <button
              @click="
                () => {
                  if (current_?.name == meal?.name) {
                    saveNewPosition();
                  } else {
                    current_ = meal;
                    current_rearrange = meal?.options_order;
                  }
                }
              "
              class="underline cursor-pointer"
            >
              {{ current_?.name == meal?.name ? "Save" : "Edit" }}
            </button>
          </div>
        </div>

        <div
          class="flex"
          v-if="current_?.name != meal?.name || !current_?.name"
        >
          <div
            v-for="option in meal.options_order"
            class="w-[fit-content] p-2 rounded cursor-pointer"
            :key="option"
            @click="() => editOptionItems(meal, option)"
          >
            <Text> {{ option }}</Text>
          </div>
        </div>
        <draggable
          v-if="current_?.name == meal?.name"
          v-model="current_rearrange"
          class="flex flex-wrap gap-2"
        >
          <template #item="{ element: option }">
            <div
              class="w-[fit-content] border p-2 rounded cursor-pointer"
              :key="option"
            >
              <Text> {{ option }}</Text>
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
    
    <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, ref as dbRef, getDatabase, set } from "firebase/database";
import { ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import draggable from "vuedraggable";

import { meals } from "@/views/store/meals";

const mealsWithOptionsOrder = ref([]);
const option_orders = ref({});
const current_ = ref(null);
const current_rearrange = ref([]);
const option_meals_to_reorder = ref({});

watchEffect(async () => {
  meals.dispatch("updateMeals");
  mealsWithOptionsOrder.value = meals.state.value.filter(
    (meal) => meal?.options_order?.length
  );
  mealsWithOptionsOrder.value.forEach((meal) => {
    option_orders.value[meal.name] = meal.option_orders;
  });
  console.log(option_orders.value);
});
const editOptionItems = (meal, option) => {
  // console.log(meal, option);
  const option_items = meal?.options[option];
  option_meals_to_reorder.value = { option_items, meal, option };
};

const saveNewPosition = async () => {
  //   console.log(current_.value);
  try {
    await set(
      dbRef(
        getDatabase(),
        `/restaurants/${current_.value.restaurant_name}/food/${current_.value.food_category}/${current_.value.index_in_firebase}/options_order`
      ),
      current_rearrange.value
    );
    toast.success("Suucessful");
    current_.value = "";
    current_rearrange.value = "";
  } catch (error) {
    console.log(error);
  }
};
const saveOptionMealsPosition = async () => {
  try {
    const value = option_meals_to_reorder.value.option_items;
    const option = option_meals_to_reorder.value.option;
    const meal = option_meals_to_reorder.value.meal;
    const path = `/restaurants/${meal?.restaurant_name}/food/${meal?.food_category}/${meal?.index_in_firebase}/options/${option}`;

    console.log(
      path,
      value?.map((item) => item?.name)
    );
    await set(dbRef(getDatabase(), path), value);
    toast.success("Successful");
    option_meals_to_reorder.value = {};
  } catch (error) {
    console.log(error);
    option_meals_to_reorder.value = {};
  }
};
const toast = useToast({ position: "top-right" });
</script>
    
    <style lang="scss" scoped>
</style>
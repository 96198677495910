import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { requestPermission } from "./requestNotificationPermission";
import { store } from "./views/store/store";
import { getDatabase, ref as dbRef, set } from "firebase/database";
// import sw from '../public/firebase-messaging-sw'


function notifyMe(title, body) {


    const notify = () => {
        const notification = new Notification(title, { body });
    }


    if (!("Notification" in window)) {
        // Check if the browser supports notifications
        // alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        notify()
        // …
    } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                notify()
            }
        });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
}

export const getPushToken = async () => {
    // console.log(store.state.user)
    const uid = store.state.user?.uid
    try {
        const currentToken = await getToken(getMessaging(), { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
        if (currentToken) {
            // console.log(currentToken)
            set(dbRef(getDatabase(), `admin_users/${uid}/pushToken`), currentToken)
            onMessage(getMessaging(), (payload) => {
                console.log("Message received. ", payload);
                notifyMe(payload.notification.title, payload.notification.body)
            });

            // Send the token to your server and update the UI if necessary
            // ...
        } else {
            // Show permission request UI
            requestPermission()
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
        // }).catch((err) => {

        // });
    } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
        // ...
    }
}

<template>
  <div class="" v-if="customer.pendingTransaction?.length">
    <Text :size="16" weight="700"> Pending Transactions</Text>
    <div
      v-for="item in customer.pendingTransaction?.filter(order =>!orders?.[order?.id]?.isSchedule)"
      :key="item.id"
      class="flex items-center my-1 justify-between"
    >
      <Text :size="16">
        <OrderID pretty :order="orders[item.id]" /> -
        {{ formatPrice(item.amount) }} ( {{ stat(orders[item.id]) }})
      </Text>

      <ue-button @click="() => clearPendingTransactions(item.id)"
        >Clear</ue-button
      >
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import { formatPrice } from "@/util";
import UeButton from "@/components/UeButton.vue";
import { removeUserPendingTransaction } from "../Orders/removeUserPendingTransaction";
import { get, getDatabase, ref as dbRef } from "firebase/database";
import { useToast } from "vue-toast-notification";
import { defineAsyncComponent, defineComponent, ref, watchEffect } from "vue";
import OrderID from "@/components/OrderID.vue";

const props = defineProps({
  customer: Object,
  onSuccess: Function,
});
const toast = useToast({ position: "top-right" });
const orders = ref({});

const stat = (order) => {
  if (order?.status?.Cancelled?.state) return "Cancelled";
  if (order?.status?.AwaitingPayment?.state) return "Unconfirmed";
  if (order?.status?.Delivered?.state) return "Delivered";
  if (order?.status?.Pickup?.state) return "Picked Up";
  return "";
};

watchEffect(async () => {
  props.customer?.pendingTransaction?.forEach(async (element) => {
    // console.log(element);
    const order = (
      await get(dbRef(getDatabase(), `orders/${element?.id}`))
    ).val();

    orders.value[element?.id] = order;
  });
});

const clearPendingTransactions = async (orderId) => {
  try {
    const order = (await get(dbRef(getDatabase(), `orders/${orderId}`))).val();
    //   console.log(props.customer.uid, order);
    await removeUserPendingTransaction({
      customer_uid: props.customer.uid,
      order,
    });
    const newPendings = (
      await get(
        dbRef(getDatabase(), `users/${props.customer.uid}/pendingTransaction`)
      )
    ).val();
    props.onSuccess(newPendings);
    toast.success("successful");
  } catch (error) {}
};
</script>

<style lang="scss" scoped>
</style>
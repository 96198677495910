
// import { getData, projectFunctions } from "@/firebase/config";
// import { sum } from "@/util";
// import dayjs from "dayjs";
import { get, getDatabase, ref as dbRef, set, onValue } from "firebase/database";
// import { httpsCallable } from "firebase/functions";
// import { useToast } from 'vue-toast-notification';
import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex"
// import { useRouter } from "vue-router";


export const meals = createStore({
    state() {
        return {
            value: [],
            genericSoldOutModal: false,
            rewards :  []
        }
    },
    mutations: {
        updateMeals: (state, list) => {
            state.value = list
        },
        updateRewards: (state, list) => {
            state.rewards = list
        },
        openGenericSoldOutModal: (state, payload) => {
            state.genericSoldOutModal = payload
        }
    },
    actions: {
        async updateMeals({ commit }) {


            const getMeals = async (snapshot) => {
                let list = [], rewards_meal_list = [];
                // const snapshot = await get(dbRef(getDatabase(), "restaurants"));
                if (snapshot.val()) {
                    const restaurants = Object.entries(snapshot.val());
                    restaurants.map((restaurant) => {
                        const restaurant_name = restaurant[0],
                            restaurant_data = restaurant[1];
                        if (restaurant_data?.food) {
                            const food_in_restaurant = Object.entries(restaurant_data?.food);
                            food_in_restaurant.map((category) => {
                                const food_category = category[0],
                                    food_in_category = category[1];
                                food_in_category.map((food_item, i) => {
                                    const item = {
                                        ...food_item,
                                        restaurant_name,
                                        food_category,
                                        index_in_firebase: i,
                                        is_extra: false,
                                    };
                                    // console.log(food_item)
                                    list.push(item);
                                });
                            });
                        }
                        if (restaurant_data?.reward) {
                            const reward_meals_restaurant = Object.values(restaurant_data?.reward);

                            reward_meals_restaurant.map((food_item, i) => {
                                const item = {
                                    ...food_item,
                                    restaurant_name,
                                    food_category: null,
                                    index_in_firebase: i,
                                    is_extra: false,
                                };
                                rewards_meal_list.push(item);
                            });
                        }
                    });
                    commit("updateMeals", list);
                    commit("updateRewards", rewards_meal_list);
                }
            };

            onValue(dbRef(getDatabase(), "restaurants"), (snapshot) => {
                console.log('----')
                if (snapshot.val()) getMeals(snapshot);
            });
        },

    },
    plugins: [createPersistedState()]
})
<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <v-dialog v-model="pickupModal">
      <div class="bg-white rounded mx-auto w-[400px]">
        <MealModal
          :title="`${pick_status ? 'Deactivate' : 'Activate'} PickUp?`"
          :content="`Are you certain you want to ${
            pick_status ? 'deactivate' : 'activate'
          } pickup?`"
          :rightClick="() => setPickupStatus()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :closeBtn="pick_status"
          :tickBtn="!pick_status"
          :oncancel="() => (pickupModal = false)"
        />
      </div>
    </v-dialog>

    <v-dialog v-model="restaurant_to_open">
      <div class="bg-white rounded mx-auto w-[400px]">
        <MealModal
          :title="`${restaurant_to_open?.open ? 'Close' : 'Open'} ${
            restaurant_to_open?.name
          }?`"
          :content="`Are you certain you want to ${
            restaurant_to_open.open ? 'close' : 'open'
          } ${restaurant_to_open?.name}?`"
          :rightClick="() => open_close_restaurant()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :closeBtn="restaurant_to_open.open"
          :tickBtn="!restaurant_to_open.open"
          :oncancel="() => (restaurant_to_open = false)"
        />
      </div>
    </v-dialog>

    <v-dialog v-model="restaurant_to_hide_or_show">
      <div class="bg-white rounded mx-auto w-[400px]">
        <MealModal
          :title="`${restaurant_to_hide_or_show?.hidden ? 'Show' : 'Hide'} ${
            restaurant_to_hide_or_show?.name
          }?`"
          :content="`Are you certain you want to ${
            restaurant_to_hide_or_show.hidden ? 'Show' : 'Hide'
          } ${restaurant_to_hide_or_show?.name}?`"
          :rightClick="() => hide_show_restaurant()"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :closeBtn="restaurant_to_hide_or_show.hidden"
          :tickBtn="!restaurant_to_hide_or_show.hidden"
          :oncancel="() => (restaurant_to_hide_or_show = false)"
        />
      </div>
    </v-dialog>

    <div class="px-[40px] bg-light w-full h-full">
      <TopNav :onSearch="search" :searchBar="false" :PrevNextIcon="false" />
      <div class="flex gap-3 cursor-pointer">
        <Text
          :size="16"
          :weight="400"
          :class="`${currPage == 'restaurants-time' && 'underline'}`"
          @click="() => (currPage = 'restaurants-time')"
        >
          Restaurants time
        </Text>
        <Text
          :size="16"
          :weight="400"
          :class="`${currPage == 'others' && 'underline'}`"
          @click="() => (currPage = 'others')"
        >
          Others
        </Text>
      </div>
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700"> Restaurants </Text>
              <!-- <Text :size="14" :weight="700" class="capitalize"> {{   }} </Text> -->
            </h1>

            <ue-button
              @click="saveChanges"
              v-if="currPage == 'restaurants-time'"
              variant="md"
              >Save</ue-button
            >
          </div>
          <div class="flex mt-5 items-center gap-[0px] justify-end">
            <!-- <button class="kitchenBtn border"> -->
            <v-switch
              color="blue"
              label="View extended times"
              @change="
                (e) => {
                  open_time_key = e.target.checked
                    ? 'extended_open_time'
                    : 'open_time';
                }
              "
            ></v-switch>
          </div>
        </div>
      </div>
      <Text :size="20"
        >You are viewing restaurants
        <span class="text-[blue] font-bold uppercase">{{
          open_time_key === "open_time" ? "regular" : "extended"
        }}</span>
        close and open times!</Text
      >
      <section
        v-if="currPage == 'restaurants-time'"
        class="!p-0 flex flex-col gap-2 mb-4"
      >
        <div class="w-[100%]" :key="open_time_key">
          <draggable
            v-model="restaurants"
            class="bg-white px-3 py-3 w-[100%] overflow-x rounded"
          >
            <template #item="{ element: restaurant, index: index }">
              <div
                class="bg-white px-2 my-2 py-3 rounded"
                @click="() => addExtended(restaurant)"
              >
                <div class="flex justify-between items-center gap-2">
                  <div class="flex items-center">
                    <div class="">
                      <Text :size="20" weight="600">{{ restaurant.name }}</Text>
                    </div>
                    <img :src="restaurant?.logo" class="w-[50px]" alt="" />
                    <Text class="pl-5" :size="12" weight="600">{{
                      !restaurant.open ? "Closed" : "Opened"
                    }}</Text>
                  </div>
                  <div class="flex gap-2 items-center">
                    <ue-button
                      v-if="restaurant?.isTemporary"
                      class="pr-5 ue-btn h-[auto]"
                      @click="() => (restaurant_to_hide_or_show = restaurant)"
                    >
                      {{ restaurant?.hidden ? "Show" : "Hide" }} restaurant
                    </ue-button>
                    <ue-button
                      class="pr-5 ue-btn h-[auto]"
                      @click="() => (restaurant_to_open = restaurant)"
                    >
                      {{ restaurant?.open ? "Close" : "Open" }} restaurant
                    </ue-button>
                    <router-link
                      class="cursor-pointer action-link"
                      :to="`/operator/restaurants/${restaurant.name}`"
                    >
                      View
                    </router-link>
                  </div>
                </div>
                <div class="px-2">
                  <!-- <Text :size="14">Open times</Text> -->
                  <table
                    class="gap- w-100"
                    v-if="restaurant?.[open_time_key]?.length"
                  >
                    <thead>
                      <tr class="border-b">
                        <td></td>
                        <td
                          v-for="(time, i) in restaurant?.[open_time_key]"
                          :key="i"
                        >
                          {{ daysOfWeek[i] }}
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class="border">
                          <Text :size="16"> Open </Text>
                        </td>

                        <td
                          v-for="(time, i) in restaurant?.[open_time_key]"
                          :key="i"
                          class="border"
                        >
                          <Text class="cursor-pointer text-left" :size="16">
                            <!-- {{ toDate(time.open).display }} -->
                            <!-- {{ dayjs().day() >= time.closeDay ? "" : "" }} {{}}
                            <br />
                            {{
                              dayjs()
                                .set("day", time.openDay)
                                .set("hour", time.open)
                                .set("minute", 0)
                                .set("second", 0).format('MMM DD, hh:mmA')
                            }}
                            <br />
                            {{
                              dayjs()
                                .set("day", time.closeDay)
                                .set("hour", time.close)
                                .set("minute", 0)
                                .set("second", 0).format('MMM DD, hh:mmA')
                            }} -->
                            <input
                              type="time"
                              :defaultValue="toDate(time.open).defaultValue"
                              :name="`restaurants/${restaurant.name}/${open_time_key}/${i}/open/`"
                              @input="(e) => changeTime(e, i)"
                            />
                          </Text>
                        </td>
                      </tr>
                      <tr class="border">
                        <td>
                          <Text :size="16">Close</Text>

                          <div class="mt-3 pl-3 flex items-center gap-2">
                            <input
                              @change="
                                (e) =>
                                  appendAllDays(
                                    e.target.checked,
                                    restaurant,
                                    index
                                  )
                              "
                              type="checkbox"
                              :checked="
                                restaurant?.[open_time_key]?.every(
                                  (time, i) => time.closeDay != i
                                )
                              "
                            />
                          </div>
                        </td>
                        <td
                          v-for="(time, i) in restaurant?.[open_time_key]"
                          :key="i"
                          class="border"
                        >
                          <!-- import dayjs from "dayjs"; -->
                          <Text class="cursor-pointer text-left" :size="16">
                            <!-- {{ toDate(time.close).display }} -->
                            <input
                              type="time"
                              :defaultValue="toDate(time.close).defaultValue"
                              :name="`restaurants/${restaurant.name}/${open_time_key}/${i}/close/`"
                              @input="changeTime"
                            />
                          </Text>
                          <div class="mt-3 pl-1 flex items-center gap-2">
                            Next Day
                            <input
                              type="checkbox"
                              :checked="!(time.closeDay == i)"
                              :name="`restaurants/${restaurant.name}/${open_time_key}/${i}/closeDay/`"
                              @change="(e) => appendDay(e, i, index)"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </draggable>
        </div>
        <div class="w-[100%] bg-white py-4 px-3 mb-3">
          <Text :size="20" :weight="600">Pickup Status</Text>
          <ue-button
            @click="() => (pickupModal = true)"
            class="mt-3 w-[fit-content]"
            variant="md"
            disabled
          >
            {{ pick_status ? "Deactivate" : "Activate" }}
          </ue-button>

          <!-- <Fees /> -->
        </div>
      </section>

      <app-edits v-if="currPage == 'others'" />
    </div>

    <!-- <OrderModal v-else-if="selectedOrder?.id" :selectedOrder="selectedOrder" :orders="orders"/> -->
  </layout>
</template>
          
        <script setup>
import { onMounted, ref, watchEffect } from "vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import Layout from "@/partials/Layout/layout.vue";
import { get, getDatabase, ref as dbRef, set, update } from "firebase/database";
import dayjs from "dayjs";
import { useToast } from "vue-toast-notification";

import draggable from "vuedraggable";
import MealModal from "@/components/MealModal.vue";
import AppEdits from "./AppEdits.vue";

import router from "@/router";

const restaurants = ref([]);
const changesBatch = ref({});
const pickupModal = ref(false);
const pick_status = ref(false);
const restaurant_to_open = ref(false);
const currPage = ref("restaurants-time");
const restaurant_to_hide_or_show = ref(null);
const open_time_key = ref("open_time");

const addExtended = async (restaurant) => {
  console.log(restaurant.name, restaurant.open_time);
  const newOPentime = restaurant.open_time?.map((time) => ({
    close: time.close,
    closeDay: time.closeDay,
    open: time.open,
    openDay: time.openDay,
  }));
  console.log(newOPentime);
  await set(
    dbRef(getDatabase(), `/restaurants/${restaurant.name}/extended_open_time`),
    newOPentime
  );
  toast.success("-----");
};

const toDate = (time) => {
  // const now = new Date();
  const vals = String(time || "").split(".");
  const hour = String(vals[0] || "").padStart(2, "0");
  const mins = String(vals[1] || "").padStart(2, "0");
  // console.log(str);
  // let hourStr = `${hour}:`;
  if (hour === 24) hourStr = `00:${mins}`;

  //  const str = `${now.toDateString()} ${hourStr}`;
  // const display = `${dayjs(str).format("hh:mm A")}`;
  // const defaultValue = `${dayjs(str).format("HH:mm")}`;
  const defaultValue = `${hour === "24" ? "00" : hour}:${mins}`;
  // console.log({ hour, mins, defaultValue });
  return {
    // display,
    defaultValue,
  };
};

const getRestaurants = async () => {
  const res = await get(dbRef(getDatabase(), "restaurants"));
  const createArr = Object.entries(res?.val())?.map((prev) => ({
    ...prev[1],
    name: prev[0],
  }));
  restaurants.value = createArr.toSorted((a, b) => a?.position - b?.position);
};
onMounted(() => {
  getRestaurants();
});

const toast = useToast({ position: "top-right" });

const changeTime = (e, i) => {
  const timeSplit = e.target.value?.split(":");
  const hour = parseInt(timeSplit[0]),
    mins = parseInt(timeSplit[1]);
  const value = `${hour}.${mins}`;
  // console.log({ value, hour, mins });
  changesBatch.value[e.target.name] = parseFloat(value);
  if (value == 0) changesBatch.value[e.target.name] = 24;
};

const appendDay = (e, i, res_index) => {
  const isNextDay = e.target.checked;
  let value = i;
  if (isNextDay) value = i + 1;
  if (value == 7) value = 0;
  // console.log(e.target.name, value);
  restaurants.value[res_index][open_time_key.value][i].closeDay = value;
  changesBatch.value[e.target.name] = value;
};

const appendAllDays = (checked, prev_restautants, index) => {
  prev_restautants?.[open_time_key.value]?.map((time, i) => {
    let value = i;
    if (checked) value = i + 1;
    if (value == 7) value = 0;
    restaurants.value[index][open_time_key.value][i].closeDay = value;
    const name = `restaurants/${prev_restautants?.name}/${open_time_key.value}/${i}/closeDay/`;
    changesBatch.value[name] = value;
  });
};
const saveChanges = async () => {
  const updatePositions = restaurants?.value.map(async (restaurant, index) => {
    await set(
      dbRef(getDatabase(), `restaurants/${restaurant.name}/position`),
      index + 1
    );
  });

  // for (const path in changesBatch.value) {
  //   if (Object.prototype.hasOwnProperty.call(changesBatch.value, path)) {
  //     const value = changesBatch.value[path];
  //     if (path.includes("extended_open_time")) {
  //       const restaurant = path.split("/")[1];
  //       const timeKey = path.split("/")[4];
  //       const newPath = `/restaurants/${restaurant}/open_time/1/extended${capitalize(timeKey)}`;
  //       changesBatch.value[newPath] = value;
  //     }
  //   }
  // }

  const saveOrderPromise = async () => {
    await set(
      dbRef(getDatabase(), `order/`),
      restaurants?.value.map((restaurant) => restaurant?.name)
    );
  };

  saveOrderPromise();
  Promise.all([...updatePositions]);
  // console.log(changesBatch.value);
  const changes = Object.entries(changesBatch.value);
  if (changes.length) {
    const allUpdates = changes.map(async (batch) => {
      const path = batch[0];
      const value = batch[1];
      return await set(dbRef(getDatabase(), path), value);
    });
    try {
      // console.log(changesBatch.value);
      await Promise.all(allUpdates);
      changesBatch.value = {};
      getRestaurants();
    } catch (error) {
      console.log(error);
    }
  }
  toast.success("Changes complete");
};
watchEffect(async () => {
  const status = await get(dbRef(getDatabase(), "pickup_status"));
  pick_status.value = status.val();
});

const open_close_restaurant = async () => {
  const value = Boolean(restaurant_to_open?.value?.open);
  try {
    await set(
      dbRef(
        getDatabase(),
        `/restaurants/${restaurant_to_open.value?.name}/open`
      ),
      !value
    );

    const indexChanged = restaurants.value
      ?.map((restaurant) => restaurant?.name)
      .indexOf(restaurant_to_open?.value?.name);

    restaurants.value[indexChanged].open = !restaurant_to_open?.value?.open;
    toast.success(
      `Restaurant is ${
        !restaurant_to_open?.value?.open ? "closed" : "openned"
      } successfully`
    );
    restaurant_to_open.value = false;
  } catch (error) {
    console.log(error);
  }
};
const hide_show_restaurant = async () => {
  const value = Boolean(restaurant_to_hide_or_show?.value?.hidden);
  try {
    await set(
      dbRef(
        getDatabase(),
        `/restaurants/${restaurant_to_hide_or_show.value?.name}/hidden`
      ),
      !value
    );

    const indexChanged = restaurants.value
      ?.map((restaurant) => restaurant?.name)
      .indexOf(restaurant_to_hide_or_show?.value?.name);

    restaurants.value[indexChanged].hidden =
      !restaurant_to_hide_or_show?.value?.hidden;
    toast.success(
      `Restaurant will now be ${
        !restaurant_to_hide_or_show?.value?.hidden ? "hidden" : "shownn"
      } in customers app`
    );
    restaurant_to_hide_or_show.value = false;
  } catch (error) {
    console.log(error);
  }
};

const setPickupStatus = async () => {
  // console.log(pick_status.value);
  await set(dbRef(getDatabase(), "pickup_status/"), !pick_status.value);
  toast.success("Successful");
  pickupModal.value = false;
  pick_status.value = !pick_status.value;
};

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]; // array of days sorted
</script>
          
          <style scoped lang="scss">
table {
  thead {
    td {
      font-size: 16px;
      font-weight: 600;
    }
  }
  tr {
    width: 100%;
    justify-content: space-between;
  }
  td {
    padding: 5px;
    // width: 100%;
  }
  .action-link {
    text-decoration: none;
    color: #f60100;
  }
}
</style>
<script setup>
import { onMounted, ref, watchEffect } from "vue";
import {
  GoogleMap,
  Circle,
  Marker,
  Rectangle,
  Polyline,
} from "vue3-google-map";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";

const props = defineProps({
  locations: Array,
});
const delivery_locations = ref([]);
watchEffect(() => {
  delivery_locations.value = props.locations;
  console.log(delivery_locations.value);
});

const delivery_range = ref([]);
const isLoading = ref(false);
const rangesMarkers = ref({});

const flightPath = ref({});
const flightPath2 = ref({});

const fetchRangeData = async () => {
  const res = await get(dbRef(getDatabase(), "range"));
  if (res?.val()) delivery_range.value = res?.val();
  const island_polygon = res?.val()?.island_polygon;
  // const test = {
  //   island: {
  //     latitude: [6.388276, 6.561045],
  //     longitude: [3.3603171, 3.6555],
  //   },
  // };
  for (const key in delivery_range.value) {
    if (Object.prototype.hasOwnProperty.call(delivery_range.value, key)) {
      const place = key;
      rangesMarkers.value[place] = [];
      const latitudes = delivery_range.value[key]?.latitude || [];
      const longitudes = delivery_range.value[key]?.longitude || [];
      latitudes.forEach((latitude, i) => {
        longitudes.forEach((longitude, i2) => {
          const center = { lat: latitude, lng: longitude };
          rangesMarkers.value[place].push(center);
        });
      });
    }
  }

  // console.log(rangesMarkers.value);
  const order = [0, 1, 3, 2, 0];
  flightPath.value = {
    path: order.map((num) => rangesMarkers.value.island[num]),
    geodesic: true,
    strokeColor: "#FF0000",
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };
  console.log(flightPath.value);
  // const (island_polygon?.[0]?.map(coord)=>({lat:coord[0],lng:coord[1]}))
  console.log(island_polygon?.[0]);
  flightPath2.value = {
    path: island_polygon?.[0]?.map((coord) => ({
      lng: coord[0],
      lat: coord[1],
    })),
    geodesic: true,
    strokeColor: "green",
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };
  console.log(flightPath2.value);

  island_polygon?.[0]
  ?.map((coord) => ({ lng: coord[0], lat: coord[1] }))
  ?.forEach((element, i) => {
    markers.value[Math.random()] = {
      position: element,
      label: `Point ${i || 'Start'}`,
      title: `Point ${i}`,
      key: i,
    };
  });
  
};


onMounted(() => {
  fetchRangeData();
});

const ueKitchen = ref({ lat: 6.45392, lng: 3.47739 });

const circles = ref({});
const markers = ref({});

watchEffect(() => {
  delivery_locations.value.forEach((delivery_location) => {
    const center = {
      lat: parseFloat(delivery_location?.latitude),
      lng: parseFloat(delivery_location?.longitude),
    };
    const radius = parseFloat(delivery_location?.radius) || 10;
    const key = delivery_location?.address;
    circles.value[key] = {
      center: center,
      radius: radius * 100,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      key,
    };

    markers.value[key] = {
      position: center,
      label: delivery_location.address,
      title: delivery_location.address,
      key,
    };
  });

  // rangesMarkers.value?.island?.forEach((element, i) => {
  //   markers.value[Math.random()] = {
  //     position: element,
  //     label: `range ${i}`,
  //     title: `range ${i}`,
  //     key: i,
  //   };
  // });
});
</script>

<template>
  <GoogleMap
    :key="delivery_locations.length"
    api-key="YOUR_GOOGLE_MAPS_API_KEY"
    style="width: 100%; height: 500px"
    mapTypeId="terrain"
    :center="ueKitchen"
    :zoom="12"
  >
    <Circle v-for="circle in circles" :options="circle" :key="circle.key" />
    <Marker v-for="marker in markers" :options="marker" :key="marker.key" />
    <Polyline :options="flightPath" />
    <Polyline :options="flightPath2" />
    <!-- <Marker v-for="marker in markers" :options="marker" :key="marker.key" /> -->
    <Rectangle />
  </GoogleMap>
</template>
<template>
  <div
    class="flex flex-wrap justify-between"
    v-if="selectedOrder?.locationType === 'Delivery'"
  >
    <div
      class="flex flex-wrap gap-2 items-center py-2"
      v-if="!selectedOrder?.status?.Delivered?.state"
    >
      <ue-select
        class="ue-select !w-[200px]"
        :getOptionLabel="(user) => user.first_name"
        v-model="rider"
        :options="[...riders]"
        placeholder="Assign rider"
      >
        <template #option="option">
          <span>{{ option.first_name }} {{ option.last_name }} </span>
        </template>
      </ue-select>
      <!-- <Text   :size="16" :weight="600"> Assign New Rider</Text> -->
      <ue-button :disabled="assign_rider_loading" @click="assignRider">
        {{ assign_rider_loading ? "Loading..." : "Assign New Rider" }}
      </ue-button>
      <ue-button
        v-if="
          selectedOrder?.courier?.name &&
          !selectedOrder?.status?.Dispatched?.state
        "
        :disabled="remove_rider_loading"
        @click="removeRider"
      >
        {{ remove_rider_loading ? "Loading" : "Remove rider" }}
      </ue-button>
    </div>

    <div class="flex gap-2 items-center py-2">
      <!-- {{ current?.text }} -->
      <button
        :disabled="loading"
        class="text-white p-2 rounded font-bold capitalize"
        :class="loading ? `bg-[grey]` : 'bg-danger'"
        v-if="current?.text"
        @click="updateTrip"
      >
        {{ loading ? "loading..." : current?.text }}
      </button>
      <button
        class="bg-success text-white p-2 rounded font-bold capitalize cursor-click"
        v-else
      >
        Trip Completed
      </button>
    </div>
  </div>
  <div
    v-if="selectedOrder?.locationType === 'Delivery'"
    class="w-full rounded-[16px] flex flex-wrap justify-between px-5 sm:px-2 bg-white mb-5 py-4 items-center"
  >
    <div
      class="flex items-center gap-[20px]"
      v-if="selectedOrder?.courier?.name"
    >
      <img
        :src="selectedOrder?.courier?.image_url"
        alt=""
        v-if="selectedOrder?.courier?.image_url"
        class="w-[100px] rounded h-[100px] object-cover"
      />
      <div
        v-else
        class="w-[100px] flex items-center font-bold rounded h-[100px] object-cover"
      >
        No photo
      </div>
      <div class="flex-col items-center">
        <Text :color="''" :size="17" :weight="500" class=""> Rider </Text>
        <Text :color="'#000000'" :size="20" :weight="600" class="capitalize">
          {{ selectedOrder?.courier?.name }}
        </Text>
      </div>
    </div>

    <div v-if="selectedOrder?.courier?.name">
      <Text :color="''" :size="17" :weight="500" class="">
        <Call /> Phone
      </Text>
      <Text class="flex gap-[20px]" :weight="600" :size="16"
        >{{ selectedOrder?.courier?.phone }}
      </Text>
    </div>

    <div class="flex flex-col p-[20px]" v-if="selectedOrder?.courier?.name">
      <Text :color="''" :size="17" :weight="500" class=""> Rider's note </Text>
      <Text :color="'#000000'" :size="16" :weight="600" class="capitalize">
        {{ selectedOrder?.customer?.deliveryAddress?.direction }}
      </Text>
      <!-- </div> -->
    </div>

    <Text
      v-if="!selectedOrder?.courier?.name"
      :color="'red'"
      :weight="600"
      :size="16"
    >
      Courier unassigned
    </Text>
  </div>
  <!-- <div v-else>

  </div> -->
</template>

<script>
import Text from "@/components/Text.vue";
import { useRoute } from "vue-router";
import { onMounted, ref, watchEffect } from "vue";
import {
  createTimeLine,
  saveMealPoint,
  sendPushNotification,
  store,
} from "@/views/store/store";
import UeButton from "@/components/UeButton.vue";
import {
  getDatabase,
  set,
  ref as dbRef,
  remove,
  onValue,
  get,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { useToast } from "vue-toast-notification";
import dayjs from "dayjs";
import { getMealPointPrice } from "@/util/getMealPointPrice";
import { extra_permissions_keys } from "@/views/main/Operator/Accounts/extra_permissions";
import { removeOrderFromBatch } from "@/util/removeOrderFromBatch";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";

export default {
  props: {
    selectedOrder: Object,
  },
  components: {
    Text,
    UeButton,
  },
  setup(props) {
    const path = useRoute().path;
    const selectedOrder = ref({});
    const riders = ref([]);
    const rider = ref({});
    const loading = ref(false);
    const route = useRoute();
    const pushToken = ref("");
    const listen_change = ref("");

    const assign_rider_loading = ref(false);
    const remove_rider_loading = ref(false);

    const actions = [
      {
        text: "Start trip",
        message: `started the trip`,
        _at: "Dispatched",
        _to: "Arrived",
        notification: "Your order is out for delivery",
      },
      {
        text: "Rider has arrived ",
        message: `arrived at the customer's address`,
        _at: "Arrived",
        _to: "Delivered",
        notification: "Your order has arrived",
      },
      {
        text: "Order delivered",
        message: `delivered the order`,
        _at: "Delivered",
        _to: false,
        notification: "Your order has been delivered",
      },
    ];

    watchEffect(() => {
      props.selectedOrder;
      if (props.selectedOrder) selectedOrder.value = props.selectedOrder;
      riders.value = store.state.riders.filter(
        (rider) => rider?.status === "online"
      );
      // console.log(riders.value);
    });

    const toast = useToast({ position: "top-right" });
    const assignRider = async () => {
      assign_rider_loading.value = true;
      // console.log(rider.value);
      const new_rider = {
        id: rider.value?.id,
        name: rider.value.first_name + " " + rider.value.last_name,
        live_location: rider.value.live_location || null,
        phone: rider.value.phone,
        status: rider.value.status,
        plate_number: rider.value.plate_number,
        vehicle_type: rider.value.vehicle_type,
      };
      const order = selectedOrder.value;
      const old_rider = order?.courier;
      // if (selectedOrder.value.status.Dispatched.state) {
      //   toast.info("Can't assign new rider trip already started");
      // } else {
      // if (
      //   !new_rider.live_location?.longitude &&
      //   !new_rider.live_location?.latitude
      // ) {
      //   toast.error("Can't get location of new rider");
      // } else {
      try {
        if (old_rider) {
          //remove from old rider's accepted orders
          await remove(
            dbRef(
              getDatabase(),
              `/courier/${old_rider?.id}/accepted_orders/${order?.id}`
            )
          );
          // console.log('-----')
    
          // console.log("Unassign from old rider");
        }
        await removeOrderFromBatch(order);

        //add to  new rider's accepted orders
        await set(
          dbRef(
            getDatabase(),
            `/courier/${new_rider?.id}/accepted_orders/${order?.id}`
          ),
          { id: order?.id }
        );
        // console.log("assign to new rider");

        // update rider assign to the order
        await set(
          dbRef(getDatabase(), `/orders/${order?.id}/courier`),
          new_rider
        );
        await set(dbRef(getDatabase(), `/orders/${order?.id}/accepted`), true);
        console.log("order updated");

        await createTimeLine({
          orderId: selectedOrder.value?.id,
          user_type: "admin",
          uid: store.state.user.uid,
          name: store.state.user.first_name + " " + store.state.user.last_name,
          message: `${old_rider?.name ? "re" : ""}assigned rider ${
            new_rider.name
          } to this order`,
          //reassigned
        });

        toast.success("Rider assigned ");
        assign_rider_loading.value = false;
      } catch (error) {
        assign_rider_loading.value = false;
        console.log(error);
        toast.error("An error occured");
      }
      // }
      // }
    };
    // const trip_text =
    const current = ref({
      state: false,
      time: "",
      text: "",
      _at: "",
      _to: "",
      message: "",
      notification: "",
    });
    // watchEffect(() => {

    const status_path = `orders/${route.params?.id}/status`;
    const updateCurrentStatus = async () => {
      const snapshot = await get(dbRef(getDatabase(), status_path));
      const status = snapshot.val();
      selectedOrder.value.status = status;

      if (!status?.Dispatched?.state) current.value = actions[0];
      if (status?.Dispatched?.state) current.value = actions[1];
      if (status?.Arrived?.state) current.value = actions[2];
      if (status?.Delivered?.state) current.value = {};
    };
    onMounted(async () => {
      const snapshot = await get(dbRef(getDatabase(), status_path));
      const status = snapshot.val();
      updateCurrentStatus(status);
    });

    const removeRider = async () => {
      remove_rider_loading.value = true;
      const order = selectedOrder.value;
      const old_rider = order?.courier;
      console.log(order);
      try {
        await remove(
          dbRef(
            getDatabase(),
            `/courier/${order?.courier?.id}/accepted_orders/${order?.id}`
          )
        );
        await set(dbRef(getDatabase(), `/orders/${order?.id}/accepted`), false);
        await set(dbRef(getDatabase(), `/orders/${order?.id}/courier`), "");
        await createTimeLine({
          orderId: order?.id,
          user_type: "admin",
          uid: store.state.user.uid,
          name: store.state.user.first_name + " " + store.state.user.last_name,
          message: `removed rider ${old_rider?.name}`,
        });
      } catch (error) {
      } finally {
        remove_rider_loading.value = false;
      }
    };

    onMounted(async () => {
      //get push toekn ready to save time
      const token = await get(
        dbRef(
          getDatabase(),
          `/users/${selectedOrder.value.customer?.id}/pushToken`
        )
      );
      pushToken.value = token.val();
    });

    const removeIsProcessing = async () => {
      if (selectedOrder.value?.isProcessing)
        await remove(
          dbRef(getDatabase(), `orders/${selectedOrder.value?.id}/isProcessing`)
        );
    };
    const updateRiderTripHistory = async () => {
      const order = selectedOrder.value;
      const path = `/courier/${order.courier?.id}/trip_history/${order?.id}`;
      const address = `${order.customer.deliveryAddress.houseNo || ""} ${
        order.customer.deliveryAddress.houseAddress || ""
      }`;
      const right_now = dayjs().format("MMM DD YYYY, hh:mm A");
      await set(dbRef(getDatabase(), path), {
        address,
        id: order?.id,
        deliveredTime: right_now,
        dispatchedTime: order?.status?.Dispatched?.time || right_now,
      });
    };

    const updateTrip = async () => {
      const pers = store.state.user?.extra_permissions;
      const canDispatch = pers?.includes(extra_permissions_keys.start_trip);
      const canArrive = pers?.includes(extra_permissions_keys.arrive_trip);
      const canDeliver = pers?.includes(extra_permissions_keys.deliver);
      // console.log(store.state.user?.extra_permissions, current.value._at, {
      //   canDispatch,
      //   canArrive,
      //   canDeliver,
      // });

      if (!canDispatch && current.value._at == "Dispatched") {
        toast.info(`Not allowed to mark order as ${current.value._at}`);
        return;
      }
      if (!canArrive && current.value._at == "Arrived") {
        toast.info(`Not allowed to mark order as ${current.value._at}`);
        return;
      }
      if (!canDeliver && current.value._at == "Delivered") {
        toast.info(`Not allowed to mark order as ${current.value._at}`);
        return;
      }

      if (!selectedOrder.value.courier) {
        toast.info("No rider has accepted this trip");
      } else {
        if (!selectedOrder.value.status?.Assembled?.state) {
          toast.info("Meal hasn't been assembled yet");
        } else {
          const timeLinePromise = async () => {
            return await createTimeLine({
              orderId: selectedOrder.value?.id,
              user_type: "admin",
              uid: store.state.user.uid,
              name:
                store.state.user.first_name + " " + store.state.user.last_name,
              message: current.value.message,
            });
          };

          const notificationPromise = async () => {
            await sendPushNotification({
              token: pushToken.value,
              title: ``,
              body: current.value.notification,
              orderId: selectedOrder.value?.id,
            });
          };

          const rewardReferrer = httpsCallable(projectFunctions,'rewardReferrer')
          const mealPointPromise = async () => {
            // console.log("first");
            const random = new Date().getTime().toString();
            const mealPointID = random.substring(
              random.length - 6,
              random.length - 1
            );
            const mealPointID_path = `orders/${selectedOrder.value?.id}/mealPointSaved/${mealPointID}`;
            const alreadyGotMealPoint_res = await get(
              dbRef(
                getDatabase(),
                `orders/${selectedOrder.value?.id}/mealPointSaved/`
              )
            );
            // const qur = query(
            //   dbRef(
            //     getDatabase(),
            //     `users/${selectedOrder.value.customer.id}/mealPoint/history/`
            //   ),
            //   orderByChild("orderId"),
            //   equalTo(selectedOrder.value.id)
            // );
            // const mealPointHistory = await get(qur);
            // console.log(mealPointHistory?.val());
            // if (!mealPointHistory.val()) {
            // toast.
            if (!alreadyGotMealPoint_res?.val()) {
              saveMealPoint({
                email: selectedOrder.value.customer.email,
                total_price: getMealPointPrice(selectedOrder.value),
                userId: selectedOrder.value.customer?.id,
                pointType: "add",
                point: "",
                deliveryType: "delivery",
                orderId: selectedOrder.value?.id,
              })
                .then(() => {
                  set(dbRef(getDatabase(), mealPointID_path), true);
                })
                .catch(() =>
                  set(dbRef(getDatabase(), mealPointID_path), false)
                );
            }
            // }
          };

          const updateStatusPromise = async () => {
            await set(
              dbRef(
                getDatabase(),
                `orders/${selectedOrder.value?.id}/status/${current.value._at}`
              ),
              {
                state: true,
                time: dayjs().format("MMM DD YYYY, hh:mm A"),
              }
            );
            // updateCurrentStatus();
          };

          loading.value = true;
          let promises = [];
          // console.log(current.value);

          promises.push(removeIsProcessing());
          if (current.value?._at == "Delivered") {
            promises.push(mealPointPromise());
           if(selectedOrder.value?.referralUsed) promises.push(rewardReferrer({userId: selectedOrder?.value?.customer?.id }))
          }
          promises.push(updateStatusPromise());
          if (pushToken.value) promises.push(notificationPromise());
          promises.push(updateRiderTripHistory());

          promises.push(timeLinePromise());
          updateCurrentStatus();
          await Promise.all(promises);
          loading.value = false;
          promises = [];
        }
      }
    };
    return {
      selectedOrder,
      path,
      riders,
      rider,
      store,
      assignRider,
      assign_rider_loading,
      remove_rider_loading,
      updateTrip,
      current,
      loading,
      removeRider,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
import { createRouter, createWebHistory } from "vue-router";
import signIn from "@/views/auth/signIn.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
// import Orders from "@/views/main/Order/Orders.vue"
import Home from "@/views/main/Home/Home.vue"
// import Admin from '@/views/main/Admin/Admin.vue'
import Settings from '@/views/main/Settings/Settings.vue'
import NotFound from "@/views/ErrorPages/NotFound.vue"
//route guard
import { projectAuth } from "../firebase/config";

import ChowCity from '@/views/main/Home/Blog/Restaurants/ChowCity.vue'
import AdunKitchen from '@/views/main/Home/Blog/Restaurants/AdunKitchen.vue'
import Cindy from '@/views/main/Home/Blog/Restaurants/Cindy.vue'
import Ikoko from '@/views/main/Home/Blog/Restaurants/Ikoko.vue'

import About from '@/views/main/Home/Blog/OurStory/About.vue'
import Faqs from '@/views/main/Home/Blog/OurStory/Faqs.vue'
import MealBotFaqs from '@/views/main/Home/Blog/OurStory/MealBotPointFaqs.vue'
import TermCondition from '@/views/main/Home/Blog/OurStory/TermCondition.vue'
import PrivacyPolicy from '@/views/main/Home/Blog/OurStory/PrivacyPolicy.vue'
import PrivacyPolicyRiders from '@/views/main/Home/Blog/OurStory/PrivacyPolicyRiders.vue'


// FoodDeliveryInLekki
// FoodDeliveryInIkoyi
// FoodDeliveryInVictoriaIsland
// FoodDeliveryNearMe

import OrderSmallChops from '@/views/main/Home/Blog/ImportantLinks/OrderSmallChops.vue'
import OrderPastries from '@/views/main/Home/Blog/ImportantLinks/OrderPastries.vue'
import OrderBulkMeals from '@/views/main/Home/Blog/ImportantLinks/OrderBulkMeals.vue'

import FoodDeliveryInLekki from '@/views/main/Home/Blog/ImportantLinks/FoodDeliveryInLekki.vue'
import FoodDeliveryInIkoyi from '@/views/main/Home/Blog/ImportantLinks/FoodDeliveryInIkoyi.vue'
import FoodDeliveryInVictoriaIsland from '@/views/main/Home/Blog/ImportantLinks/FoodDeliveryInVictoriaIsland.vue'
import FoodDeliveryNearMe from '@/views/main/Home/Blog/ImportantLinks/FoodDeliveryNearMe.vue'

//kitchen
import KitchenOrders from "@/views/main/Kitchen/Order/OrderBody.vue"
import KitchenOrder from "@/views/main/Kitchen/Order/SingleOrder.vue"
import KitchenMeals from "@/views/main/Kitchen/Meals/MealBody.vue"
import Restaurants from "@/views/main/Kitchen/Restaurants/Restaurants.vue"
import Restaurant from "@/views/main/Kitchen/Restaurants/Restaurant.vue"

//inventory
import Ingredients from "@/views/main/Inventory/Ingredients/Ingredients.vue"
import Ingredient from "@/views/main/Inventory/Ingredients/Ingredient.vue"
import AddIngredients from "@/views/main/Inventory/Ingredients/AddIngredient.vue"
import Recipe from "@/views/main/Inventory/Recipe/Recipe.vue"
import AddRecipe from "@/views/main/Inventory/Recipe/AddRecipe.vue"
import Prep from "@/views/main/Inventory/Prep/Prep.vue"
import SinglePrep from "@/views/main/Inventory/Prep/SinglePrep.vue"
import Vendors from "@/views/main/Inventory/Vendors/Vendors.vue"
import AddVendor from "@/views/main/Inventory/Vendors/AddVendor.vue"

//Assembler
import AssemblerOrders from "@/views/main/Assembler/Order/OrderBody.vue"
import AssemblerOrder from "@/views/main/Assembler/Order/SingleOrder.vue"

//Operator
import OperatorDashboard from "@/views/main/Operator/Dashboard/Dashboard.vue"
import OperatorOrders from "@/views/main/Operator/Orders/OrderBody.vue"
import OperatorOrder from "@/views/main/Operator/Orders/SingleOrder.vue"
import Pickup from "@/views/main/Pickup/Pickup.vue"
import MealReview from "@/views/main/Operator/MealReview/MealReview.vue"
import RiderReview from "@/views/main/Operator/RiderReview/RiderReview.vue"
import Customers from "@/views/main/Operator/Customers/Customers.vue"
import Customer from "@/views/main/Operator/Customers/Customer.vue"
import DownloadStats from "@/views/main/Operator/Customers/DownloadStats.vue"
import DownloadStat from "@/views/main/Operator/Customers/DownloadStat.vue"
import PushNotification from "@/views/main/Operator/PushNotification/PushNotification.vue"
import PaymentMethods from "@/views/main/Operator/PaymentMethods/PaymentMethods.vue"
import ManualConfirmation from "@/views/main/Operator/ManualConfirmation/ManualConfirmation.vue"
import MealBotPoint from "@/views/main/Operator/MealBotPoint/MealBotPoint.vue"
import WalletTable from "@/views/main/Operator/Customers/WalletTable.vue"
import Accounts from "@/views/main/Operator/Accounts/UserPermissions.vue"
import AddStaff from "@/views/main/Operator/Accounts/AddStaff.vue"
import Cancelled from "@/views/main/Operator/Cancelled/Cancelled.vue"
import SingleCancelled from "@/views/main/Operator/Cancelled/SingleCancelled.vue"
import GiftCard from "@/views/main/Operator/GiftCard/GiftCard.vue"
import PromoCodes from "@/views/main/Operator/PromoCode/PromoCodes.vue"
import AddPromoCode from "@/views/main/Operator/PromoCode/AddPromoCode.vue"
import DeliveryLocations from "@/views/main/Operator/DeliveryLocations/DeliveryLocations.vue"
import BugReports from "@/views/main/Operator/BugReports/BugReports.vue"

import SinlgeUnconfirmed from '@/partials/SingleUnconfirmed.vue'
import SinlgeScheduledOrder from '@/partials/SingleScheduledOrder.vue'
import CancelledOrderView from '@/partials/SingleCancelled.vue'


//logistics
import LogisticsDashboard from '@/views/main/Logistics/Dashboard/Dashboard.vue'
import LogisticOrders from '@/views/main/Logistics/Orders/OrderBody.vue'
import LogisticOrder from '@/views/main/Logistics/Orders/SingleOrder.vue'
import RejectedOrder from '@/views/main/Logistics/RejectedOrders/RejectedOrders.vue'
import Partners from '@/views/main/Logistics/Partners/Partners.vue'
import Partner from '@/views/main/Logistics/Partners/Partner.vue'
import Riders from '@/views/main/Logistics/Riders/Riders.vue'
import Map from '@/views/main/Logistics/Map/Map.vue'
import OrderBatch from '@/views/main/Logistics/OrderBatch/OrderBatch.vue'
// import { store } from "@/views/store/store";
import { icons } from "@/assets/icons";
import Referral from "@/views/main/Operator/Referrals/Referral.vue";


//requires there to be a user
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    // next();
  }
};

//requires there to be no user
const beforeEnter = (to, from, next) => {
  window.scrollTo(0, 0)
  // alert('from')
  next()
};
const parents = ['operator', 'kitchen', 'logistics', 'assembler']

const settings = (parent) => {

  return [{
    path: `/${parent}/settings/`,
    name: `Settings ${parent}`,
    sidebar: true,
    component: Settings, public: true,
    beforeEnter: beforeEnter,
    icon: icons.settings, parent
  },
  ]
}

const unwanted_orders = (parent) => {

  return [
    {
      path: `/${parent}/scheduled-order/:id`,
      name: `${parent} Scheduled Order Action(view)`,
      component: SinlgeScheduledOrder,
      sidebar: false,
      beforeEnter: beforeEnter,
      parent,
    },
    {
      path: `/${parent}/unconfirmed/:id`,
      name: `${parent} Unconfirmed Order Action(view)`,
      component: SinlgeUnconfirmed,
      sidebar: false,
      beforeEnter: beforeEnter,
      parent,
    },
  {
    path: `/${parent}/cancelled-order/:id`,
    name: `${parent} Cancelled Order Action(view)`,
    component: CancelledOrderView,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent,
  },
  ]

  // const extras = [

  // ]
}


export const inventory_routes = [
  //inventory paths
  {
    path: "/inventory/ingredients/",
    sidebar: true,
    name: "Inventory Ingredients",
    parent: 'inventory',
    component: Ingredients,
    beforeEnter: beforeEnter,
    icon: icons.ingredients
  },
  {
    path: "/inventory/ingredients/add",
    sidebar: false,
    name: "Inventory Add Ingredient",
    parent: 'inventory',
    component: AddIngredients,
    beforeEnter: beforeEnter,
    icon: icons.ingredients
  },
  {
    path: "/inventory/ingredients/edit/:id",
    sidebar: false,
    name: "Inventory Edit Ingredient",
    parent: 'inventory',
    component: AddIngredients,
    beforeEnter: beforeEnter,
    icon: icons.ingredients
  },
  {
    path: "/inventory/ingredients/:id",
    sidebar: false,
    name: "Inventory Ingredients Action(view)",
    parent: 'inventory',
    component: Ingredient,
    beforeEnter: beforeEnter,
    icon: icons.ingredients
  },
  {
    path: "/inventory/recipes/",
    sidebar: true,
    name: "Inventory Recipes",
    parent: 'inventory',
    component: Recipe,
    beforeEnter: beforeEnter,
    icon: icons.recipe
  },
  {
    path: "/inventory/recipes/add",
    sidebar: false,
    name: "Inventory Add Recipe",
    parent: 'inventory',
    component: AddRecipe,
    beforeEnter: beforeEnter,
    icon: icons.recipe
  },
  {
    path: "/inventory/recipes/:id",
    sidebar: false,
    name: "Inventory Add Recipe",
    parent: 'inventory',
    component: AddRecipe,
    beforeEnter: beforeEnter,
    icon: icons.recipe
  },
  {
    path: "/inventory/prep/",
    sidebar: true,
    name: "Inventory Prep",
    parent: 'inventory',
    component: Prep,
    beforeEnter: beforeEnter,
    icon: icons.prep
  },
  {
    path: "/inventory/prep/:id",
    sidebar: false,
    name: "Inventory Prep Action(view)",
    parent: 'inventory',
    component: SinglePrep,
    beforeEnter: beforeEnter,
    icon: icons.prep
  },
  {
    path: "/inventory/vendors/",
    sidebar: true,
    name: "Inventory Vendors",
    parent: 'inventory',
    component: Vendors,
    beforeEnter: beforeEnter,
    icon: icons.vendors
  },
  {
    path: "/inventory/vendors/add",
    sidebar: false,
    name: "Inventory Add Vendors",
    parent: 'inventory',
    component: AddVendor,
    beforeEnter: beforeEnter,
    icon: icons.vendors
  },
  {
    path: "/inventory/vendors/:id",
    sidebar: false,
    name: "Inventory Edit Vendors",
    parent: 'inventory',
    component: AddVendor,
    beforeEnter: beforeEnter,
    icon: icons.vendors
  },

  ...settings('inventory')
]
export const logistics_routes = [
  //logistics paths
  {
    path: "/logistics/dashboard/",
    sidebar: true,
    name: "Logistics Dashboard",
    parent: 'logistics',
    component: LogisticsDashboard,
    beforeEnter: beforeEnter,
    icon: icons.dashboard
  },
  {
    path: "/logistics/orders/",
    sidebar: true,
    name: "Logistics Orders",
    parent: 'logistics',
    component: LogisticOrders,
    beforeEnter: beforeEnter,
    icon: icons.order
  }, {
    path: "/logistics/orders/:id",
    sidebar: false,
    name: "Logistics Order Action(view)",
    parent: 'logistics',
    component: LogisticOrder,
    beforeEnter: beforeEnter,
  },
  {
    path: "/logistics/rejected-orders/",
    sidebar: true,
    name: "Logistics Rejected Orders",
    parent: 'logistics',
    component: RejectedOrder,
    beforeEnter: beforeEnter,
    icon: icons.rejected_order
  },
  {
    path: "/logistics/partners/",
    sidebar: true,
    name: "Logistics Partners",
    parent: 'logistics',
    component: Partners,
    beforeEnter: beforeEnter,
    icon: icons.rejected_order
  },

  {
    path: "/logistics/partners/:key",
    sidebar: false,
    name: "Logistics Partner",
    parent: 'logistics',
    component: Partner,
    beforeEnter: beforeEnter,
    icon: icons.rejected_order
  },
  {
    path: "/logistics/riders",
    sidebar: true,
    name: "Logistics Riders",
    parent: 'logistics',
    component: Riders,
    beforeEnter: beforeEnter,
    icon: icons.rider_review
  },
  {
    path: "/logistics/riders/:id",
    sidebar: false,
    name: "Logistics Rider Details",
    parent: 'logistics',
    component: Riders,
    beforeEnter: beforeEnter,
  },
  {
    path: "/logistics/rider-review",
    sidebar: true,
    name: "Logistics Riders Review",
    parent: 'logistics',
    component: RiderReview,
    beforeEnter: beforeEnter,
    icon: icons.rider_review
  },
  {
    path: "/logistics/rider-review/:id",
    sidebar: false,
    name: "Logistics Rider Review",
    parent: 'logistics',
    component: RiderReview,
    beforeEnter: beforeEnter,
  },
  {
    path: "/logistics/map",
    sidebar: true,
    name: "Logistics Map",
    parent: 'logistics',
    component: Map,
    beforeEnter: beforeEnter,
    icon: icons.map
  },
  {
    path: "/logistics/smart-batching",
    sidebar: true,
    name: "Logistics Order Batching",
    parent: 'logistics',
    component: OrderBatch,
    beforeEnter: beforeEnter,
    icon: icons.map
  },
  ...unwanted_orders('logistics'),
  ...settings('logistics')
]
export const kitchen_routes = [
  {
    path: "/kitchen/orders/",
    name: "Kitchen Orders",
    parent: "kitchen",
    component: KitchenOrders,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.order
  },
  {
    path: "/kitchen/orders/:id",
    name: "Kitchen Order Action(view)",
    parent: "kitchen",
    component: KitchenOrder,
    sidebar: false,
    beforeEnter: beforeEnter,
  },
  {
    path: "/kitchen/restaurants/:action",
    name: "Kitchen Restaurants",
    parent: "kitchen",
    component: Restaurants,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.restaurants
  },
  {
    path: "/kitchen/meals",
    name: "Kitchen Meals",
    parent: "kitchen",
    component: KitchenMeals,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.meal
  },

  ...unwanted_orders('kitchen'),
  ...settings('kitchen')
]
export const assembler_routes = [
  {
    path: "/assembler/orders/",
    name: "Assembler Orders",
    parent: 'assembler',
    component: AssemblerOrders,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.order
  },
  {
    path: "/assembler/orders/:id",
    name: "Assembler Order Action(view)",
    parent: 'assembler',
    component: AssemblerOrder,
    sidebar: false,
    beforeEnter: beforeEnter,
  },
  {
    path: "/assembler/pick-up",
    name: "Assembler Pick Up",
    parent: 'assembler',
    component: Pickup,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.pick_up
  },
  ...unwanted_orders('assembler'),
  ...settings('assembler')
]
export const operator_routes = [
  {
    path: "/operator/",
    name: "Operator Dashboard",
    component: OperatorDashboard,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.dashboard
  },
  {
    path: "/operator/orders/",
    name: "Operator Orders",
    component: OperatorOrders,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.order
  },
  {
    path: "/operator/manual-confirmation/",
    name: "Operator Manual Confirmation",
    component: ManualConfirmation,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.manual_confirmation
  },
  {
    path: "/operator/payment/",
    name: "Operator Payments",
    component: PaymentMethods,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.manual_confirmation
  },
  {
    path: "/operator/orders/:id",
    name: "Operator Order Action(view)",
    component: OperatorOrder,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/pick-up/",
    name: "Operator Pick Up",
    component: Pickup,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.pick_up
  },
  {
    path: "/operator/cancelled/",
    name: "Operator Cancelled",
    component: Cancelled,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.cancel
  },
  {
    path: "/operator/cancelled/:id",
    name: "Operator Cancelled Action(View)",
    component: SingleCancelled,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.pick_up
  },
  {
    path: "/operator/meals",
    name: "Operator Meals",
    component: KitchenMeals,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.meal
  },
  {
    path: "/operator/partners/",
    sidebar: true,
    name: "Operator Partners",
    parent: 'operator',
    component: Partners,
    beforeEnter: beforeEnter,
    icon: icons.rejected_order
  },
  {
    path: "/operator/partners/:key",
    sidebar: false,
    name: "Operator Partner",
    parent: 'operator',
    component: Partner,
    beforeEnter: beforeEnter,
    icon: icons.rejected_order
  },
  {
    path: "/operator/downloads",
    name: "Operator Download Stats",
    component: DownloadStats,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.meal
  },
  {
    path: "/operator/downloads/:date",
    name: "Operator Download Stat (Action view)",
    component: DownloadStat,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.meal
  },
  {
    path: "/operator/restaurants/",
    name: "Operator Restaurants",
    parent: "operator",
    component: Restaurants,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.restaurants
  },
  // {
  //   path: "/operator/restaurants/:action",
  //   name: "Operator Restaurants",
  //   parent: "operator",
  //   component: Restaurants,
  //   sidebar: false,
  //   beforeEnter: beforeEnter,
  //   icon: icons.restaurants
  // },
  {
    path: "/operator/delivery-locations",
    name: "Operator Delivery Locations",
    parent: "operator",
    component: DeliveryLocations,
    sidebar: true,
    beforeEnter: beforeEnter,
    icon: icons.restaurants
  },
  {
    path: "/operator/smart-batching",
    sidebar: true,
    name: "Operator Order Batching",
    parent: 'operator',
    component: OrderBatch,
    beforeEnter: beforeEnter,
    icon: icons.map
  },
  {
    path: "/operator/restaurants/:name",
    name: "Operator Restaurant (Action View)",
    parent: "operator",
    component: Restaurant,
    sidebar: false,
    beforeEnter: beforeEnter,
    icon: icons.restaurants
  },
  {
    path: "/operator/customers",
    name: "Operator Customers",
    component: Customers,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.customer
  },
  {
    path: "/operator/meal-bot-points",
    name: "Operator MealBot Points",
    component: MealBotPoint,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.customer
  },
  {
    path: "/operator/push-notifications",
    name: "Operator Push Notifictions",
    component: PushNotification,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.push_notification
  },
 
  { 
    path: "/operator/wallet",
    name: "Operator Wallet ",
    component: WalletTable,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.wallet
  },
  {
    path: "/operator/gift-cards",
    name: "Operator Gift Card",
    component: GiftCard,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.gift_card
  },
  {
    path: "/operator/promo-codes",
    name: "Operator Promo Code",
    component: PromoCodes,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.promo_code
  },
  {
    path: "/operator/promo-codes/add",
    name: "Operator Add Promo Code",
    component: AddPromoCode,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/promo-codes/:promo_code_id",
    name: "Operator Edit Promo Code",
    component: AddPromoCode,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/customers/:uid",
    name: "Operator Customer Action(view)",
    component: Customer,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/meal-review",
    name: "Operator Meal Review",
    component: MealReview,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.meal_review
  },
  {
    path: "/operator/rider-review",
    name: "Operator Riders Review",
    component: RiderReview,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.rider_review
  },
  {
    path: "/operator/rider-review/:id",
    name: "Operator Rider Review",
    component: RiderReview,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/referrals",
    name: "Referrals",
    component: Referral,
    sidebar: true,  icon: icons.users_permissions,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/users-and-permissions",
    name: "Operator Users and permissions",
    component: Accounts,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.users_permissions
  },
  {
    path: "/operator/users-and-permissions/add-staff",
    name: "Operator Add staff",
    component: AddStaff,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/users-and-permissions/:id",
    name: "Operator Edit staff",
    component: AddStaff,
    sidebar: false,
    beforeEnter: beforeEnter,
    parent: "operator",
  },
  {
    path: "/operator/bug-reports",
    name: "Operator Bug Reports",
    component: BugReports,
    sidebar: true,
    beforeEnter: beforeEnter,
    parent: "operator",
    icon: icons.users_permissions
  },
  ...unwanted_orders('operator'),
  ...settings('operator')
]
// const import
export const blog_routes = [
  {
    path: "/chow-city",
    name: "Blog Chow City",
    component: ChowCity,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/adun-kitchen",
    name: "Blog Adun Kitchen",
    component: AdunKitchen,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/cindys",
    name: "Blog Cindy",
    component: Cindy,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/ikoko",
    name: "Blog Ikoko",
    component: Ikoko,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/about",
    name: "About",
    component: About,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/terms-and-conditon",
    name: "Terms and Condition",
    component: TermCondition,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy Customer",
    component: PrivacyPolicy,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/privacy",
    name: "Privacy Policy Riders",
    component: PrivacyPolicyRiders,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/mealbotpoint-faqs",
    name: "MealBot Point FAQs",
    component: MealBotFaqs,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Order-Small-Chops",
    name: "Order Small Chops",
    component: OrderSmallChops,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Order-pastries",
    name: "Order Pastriers",
    component: OrderPastries,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Order-Bulk-Meals",
    name: "Order Bulk Meals",
    component: OrderBulkMeals,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Food-Delivery-In-Lekki",
    name: "Food Delivery In Lekki",
    component: FoodDeliveryInLekki,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Food-Delivery-In-Ikoyi",
    name: "Food Delivery In Ikoyi",
    component: FoodDeliveryInIkoyi,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Food-Delivery-In-Victoria-Island",
    name: "FoodDeliveryInVictoriaIsland",
    component: FoodDeliveryInVictoriaIsland,
    beforeEnter: beforeEnter, isPublic: true
  },
  {
    path: "/Food-Delivery-Near-Me",
    name: "FoodDeliveryNearMe",
    component: FoodDeliveryNearMe,
    beforeEnter: beforeEnter, isPublic: true
  }

]

export const routes = [
  {
    path: "/",
    name: "Home",
    isPublic: true,
    component: Home,
    beforeEnter: beforeEnter,
  },
  {
    path: "/admin",
    name: "Admin",
    isPublic: true,
    component: signIn,
    beforeEnter: beforeEnter,
  },
  {
    path: "/signIn",
    name: "SignIn",
    isPublic: true,
    component: signIn,
    beforeEnter: beforeEnter,
  },
  {
    path: "/admin/reset-password",
    name: "ResetPassword",
    isPublic: true,
    component: ResetPassword,
    beforeEnter: beforeEnter,
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false
    },
    isPublic: true,
  },

  ...logistics_routes, ...operator_routes, ...assembler_routes, ...kitchen_routes, ...inventory_routes,
  ...blog_routes,
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
  }
});

export default router;

<template>
  <Layout>
    <div class="px-[40px] bg-light w-full h-full mainContent">
      <TopNav :PrevNextIcon="false" />
      <div class="d-flex justify-content-between p-3 w-full">
        <Text :size="27" :weight="700"> Referrals </Text>
        <!-- <time-drop-down :getDate="(e) => (timeFrame = e)" /> -->
      </div>
      <table class="order-table">
        <thead>
          <tr>
            <th class="table-header"></th>
            <th class="table-header">Referree</th>
            <th class="table-header">Referred</th>
            <th class="table-header">Code</th>

          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(referral, index) in paginatedReferrals.toReversed()"
            :key="referral.id"
            class="table-row"
            :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
          >
            <td class="table-data flex gap-2">
              {{ index + 1 }}
            </td>

            <td class="table-data">
              <customer-name :id_key="'id'" :customerId="referral?.referrer" />
            </td>

            <td class="table-data">
              <customer-name :id_key="'id'" :customerId="referral?.user" />
            </td>
            <td class="table-data">{{ referral.code }}</td>
            
          </tr>
        </tbody>
      </table>
      <Pagination
        class="py-3"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="goToPage"
      />
    </div>
  </Layout>
</template>

<script setup>
import Pagination from "@/components/Pagination.vue";
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import Layout from "@/partials/Layout/layout.vue";
import { computed, watchEffect } from "vue";
import CustomerName from "@/components/CustomerName.vue";
import dayjs from "dayjs";
import { formatPrice } from "@/util";

import {
  onValue,
  ref as dbRef,
  getDatabase,
  set,
  get,
} from "firebase/database";
import { ref } from "vue";
const referrals = ref([]);
watchEffect(async () => {
  const res = (await get(dbRef(getDatabase(), "referrals"))).val();

  const referreds = Object.entries(res).map((entry) => ({
    ...entry[1],
    code: entry[0],
  }));
  console.log(referreds);
  referreds.forEach((element) => {
    if (element.users?.length) {
      element.users?.forEach((user) => {
        // console.log(user)
        referrals.value.push({ ...element, user });
      });
    }
  });
  console.log( referrals.value)
});

const paginatedReferrals = computed(() => {
  console.log(referrals.value);
  return referrals.value;
});
</script>

<style lang="scss" scoped>
</style>
<template>
  <head>
    <title>Urban Eats {{ title ? " | " + title : "" }}</title>
  </head>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
  />
  <loading-screen />
  <router-view> </router-view>
</template>
<script>
import { provide, ref, watchEffect } from "vue";
import store from "../src/views/store/index";
import { store as vuexStore } from "../src/views/store/store";
import { runAllDefault } from "./onAllScreenLoadActions";
import { useRoute, useRouter } from "vue-router";
import { check_if_permitted_into_a_page } from "./permission";
import { useToast } from "vue-toast-notification";
import "./iconsax.css";
import { routes } from "./router";
import LoadingScreen from "./LoadingScreen.vue";
import { getMessaging, getToken, onMessage,  } from "firebase/messaging";
import { getPushToken } from "./getPushToken";
// import sw from  '../public/firebase-messaging-sw.js'

export default {
  components: {
    LoadingScreen,
  },
  mounted(){
   
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const title = ref("");

    provide("store", store);
    runAllDefault();

    const toast = useToast({ position: "top-right" });

    router.afterEach((to, from) => {
      // console.log(to.name);
      const public_paths = routes
        .filter((route) => route.isPublic)
        .map((route) => route.name);
      //  [
      //   "Admin",
      //   "SignIn",
      //   "Home",
      //   "ResetPassword",
      //   "Privacy Policy",
      //   "Terms and Condition",
      // ];

      const isNotPublicPath = public_paths.filter(
        (name) => name.replaceAll(" ", "") === to.name.replaceAll(" ", "")
      ).length;

      if (!vuexStore.state.user?.email) {
        if (!isNotPublicPath) router.push("/admin");
      } else {
        const permitted = check_if_permitted_into_a_page(to.name).permitted;
        // console.log(permitted);
        if (!permitted?.is_permitted) {
          toast.info("Sorry! you are not authorized.");
          if (from) router.push(from.path);
          if (!from) router.push("/admin");
        }
      }

      // console.log(to.name, from.name);
    });

    watchEffect(() => {
      const name = route.path.split("/")[1];
      title.value =
        name.charAt(0).toUpperCase() + name.substring(1, name.length);
    });

 
    getPushToken()
    onMessage(getMessaging(), (payload) => {
      console.log(
        "[firebase-messaging-sw.js] Received background message ",
        payload
      );
    });
  

    return {
      title,
    };
  },
};
</script>

<style>
#login {
  background: url("https://firebasestorage.googleapis.com/v0/b/mypocketcounselapp.appspot.com/o/background.jpg?alt=media&token=18521fd5-ede7-4663-ae53-fc0fe2d116da")
    center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
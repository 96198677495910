import { get, getDatabase, ref } from "firebase/database";
import { export2csv } from "../Dashboard/exportcsv";
import { json2csv } from "json-2-csv";
import dayjs from "dayjs";

export const getCustomersWithOrders = async (customers) => {
    const customersWithOrders = customers
        .filter(
            (customer) => {
                // console.log(customer?.order_history?.completed?.orders?.length || customer?.order_history?.processing?.orders?.length, customer?.order_history?.completed?.orders?.length, customer?.order_history?.processing?.orders?.length)
                return customer?.order_history?.completed?.orders?.length > 0 || customer?.order_history?.processing?.orders?.length > 0
            }
        )
        .map((customer) => {
            const processing = customer?.order_history?.processing?.orders || [];
            const completed = customer?.orders_history?.completed?.orders || [];
            const lastOrderId =
                processing[0] || completed[completed.length - 1];

            return {
                name: customer?.firstName + " " + customer?.lastName,
                phone: customer?.phoneNumber,
                lastOrderId,
                // completed, processing,
            };
        }).filter(customer => customer?.lastOrderId)
    console.log(customersWithOrders)
    const addingDates = customersWithOrders.map(async (customer) => {
        const date = (await get(ref(getDatabase(), `orders/${customer?.lastOrderId}/date`))).val()
        return {
            ...customer, lastOrderDate: dayjs(date?.replaceAll('"', '')).format('DD MMM YYYY. hh:mmA ')
        }
    })
    const res = await Promise.all(addingDates)
    if (res.length) {
        const csv = json2csv(res)
        export2csv(csv, "customerswithorders.csv", "text/csv;charset=utf-8;");
    }
    return (res)
};
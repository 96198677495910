<template>
  <br />
  <Text :weight="600" :size="18">Location range</Text>
  <div class="flex gap-3 flex-wrap">
    <div v-for="(value, key) in delivery_range" :key="key">
      <span class="capitalize font-bold"> {{ key }}</span> <br />
      <div v-for="(value2, key2) in value" :key="key2">
        {{ key2 }} : {{ value2?.join(", ") }}
      </div>
    </div>

    <!-- <div
      class="border p-3 w-[300px] rounded mb-2"
      v-for="({ key, value }) of  delivery_range"
      :key="key"
    >
      <Text :weight="600" class="capitalize"> {{ key }}</Text>
      <hr />
      <div class="mt-3">
        Delivery time
        <input class="rounded border" type="text" />
      </div>
    </div> -->
  </div>
  <!-- <ue-button class="w-[200px] p-2" @click="saveChanges">Save</ue-button> -->
  <div class="flex flex-wrap gap-2">
    <div
      class="flex flex-col gap-2 my-4"
      v-for="(point, i) in polygon"
      :key="point.name"
    >
      <div class="flex items-center">
        <div class="px-1 border rounded cursor-pointer m-1" @click="() => addPoint('left', i)">+</div> Point
        {{ i ? (i === polygon.length - 1 ? "End" : i) : "Start" }}
        <div class="px-1 border rounded cursor-pointer m-1" @click="() => addPoint('right', i)">+</div>
        <div class="px-1 border rounded cursor-pointer m-1" @click="() => deletePoint(i)">X</div>
      </div>
      <div class="flex flex-col">
        Latitude
        <input
          type="number"
          class="border rounded p-2 outline-none w-[100%]"
          v-model="point[0]"
        />
      </div>
      <div class="flex flex-col">
        Longitude
        <input
          type="number"
          class="border rounded p-2 outline-none w-[100%]"
          v-model="point[1]"
        />
      </div>
    </div>
  </div>
  <ue-button @click="savePolygonChanges"> Save </ue-button>
  <br /><br />
</template>
  
  <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toast-notification";

const delivery_range = ref([]);
const isLoading = ref(false);
const polygon = ref({});

const fetchData = async () => {
  const res = await get(dbRef(getDatabase(), "range/"));
  if (res?.val()) {
    delivery_range.value = res?.val();
    polygon.value = res?.val()?.island_polygon?.[0];
  }
};

onMounted(() => {
  fetchData();
  //   console.log(delivery_locations.value);
});
const toast = useToast({ position: "top-right" });

// const saveChanges = async () => {
//   console.log(delivery_range.value);
//   isLoading.value = true;
//   try {
//     const payload = {
//       island: {
//         latitude: [6.388276, 6.561045],
//         longitude: [3.3603171, 3.6555],
//       },
//       lagos: { latitude: [6.393, 6.702], longitude: [3.105, 3.795] },
//     };
//     // await set(dbRef(getDatabase(), "range/"), payload);
//     // toast.success("Success");
//   } catch (error) {
//     console.log(error);
//   } finally {
//     isLoading.value = false;
//   }
// };

const addPoint = (to, index) => {
  console.log(to, index);
  console.log(polygon.value);
  let i = index;
  if (to == "right") i = index + 1;

  const newPolygon = [
    ...polygon.value.slice(0, i),
    [0, 0],
    ...polygon.value.slice(i),
  ];
  console.log(newPolygon);
  polygon.value = newPolygon;
};

const deletePoint = (index) => {
  polygon.value = polygon.value.filter((poly, i) => i !== index);
  console.log(polygon.value)
};

const savePolygonChanges = async () => {
  // console.log(polygon.value);

  const startPoint = polygon.value[0];
  const endPoint = polygon.value[polygon.value?.length - 1];
  if (startPoint[0] === endPoint[0] && startPoint[1] === endPoint[1]) {
    console.log(polygon.value);
    const res = await set(
      dbRef(getDatabase(), "/range/island_polygon/0"),
      polygon.value
    );
    toast.success("Successful");
  } else {
    toast.error(
      "Point start and point end must have the same latitude and longtitude"
    );
  }
};
</script>
  
  <style lang="scss" scoped>
</style>
<template>
  <!-- <v-dialog v-model="option_meals_to_reorder.option_items">
      <div class="mx-auto rounded bg-white p-2 w-[300px]">

        <Text :size="14" weight="600" class="pb-2">
          Reorder {{ option_meals_to_reorder?.option }} items in
          {{ option_meals_to_reorder?.meal?.name }}</Text
        >
       
        <draggable v-model="option_meals_to_reorder.option_items">
          <template #item="{ element: item }">
            <div class="border mb-1 rounded p-1 cursor-move" :key="item?.name">
              <Text> {{ item?.name }}</Text>
            </div>
          </template>
        </draggable>
  
        <div class="flex justify-center mt-2 gap-2">
          <button
            class="bg-white rounded p-2 border"
            @click="
              () => {
                option_meals_to_reorder = {};
              }
            "
          >
            Cancel
          </button>
          <ue-button @click="saveOptionMealsPosition">Save</ue-button>
        </div>
      </div>
    </v-dialog> -->
  <div class="bg-white p-2 mb-3 w-100 rounded">
    <div class="flex justify-between mb-3">
      <Text :size="20" weight="600">
        (Hide/Show) Drinks and Fresh juice addons
      </Text>
      <div class="flex gap-2"></div>
    </div>

    <div class="flex gap-3 flex-col">
      <div v-for="category in categories" :key="category.id">
        <Text weight="600"> {{ category }}</Text>
        <div
          class="flex flex-wrap gap-3 items-center relative"
          style="min-width: 100% !important"
        >
          <div
            class="border p-2 rounded cursor-pointer !min-w-[150px]"
            v-for="drink in unique_drinks.filter(
              (drink) => drink.category == category
            )"
            :key="drink?.name"
          >
            <Text weight="600"> {{ drink?.name }}</Text>
            <hr class="my-1" />
            <div class="flex items-center justify-between">
              <Text
                weight="600"
                v-if="drink?.available"
                size="14"
                color="green"
              >
                Availabe
              </Text>
              <Text weight="600" size="14" color="red" v-else>
                Out of stock
              </Text>
              <check-box
                @change="(e) => onChange(e.target.checked, drink?.name)"
                :checked="drink?.show_addons"
                :disabled="!drink?.show_addons && isOver5(drink?.category)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-[100%] flex mt-2 justify-end">
      <UeButton class="w-[100px]" @click="saveChanges"> Save </UeButton>
    </div>
  </div>
</template>
      
      <script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { get, ref as dbRef, getDatabase, set } from "firebase/database";
import { ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import draggable from "vuedraggable";
// import CheckBoxVue
import { meals } from "@/views/store/meals";
import CheckBox from "@/components/CheckBox.vue";
import { getAllOccurancesOfItem } from "../Meals/getAllOccurancesOfItem";
import { multipleUpdates } from "@/views/store/store";
import { loadingScreen } from "@/views/store/loadingScreen";

const toast = useToast({ position: "top-right" });
const all_drinks = ref([]);
const unique_drinks = ref([]);
const drinksToChange = ref({});
const categories = ["FRESH JUICE", "DRINK"];

watchEffect(() => {
  all_drinks.value = meals.state.value.filter((meal) =>
    categories.includes(meal?.food_category)
  );
  unique_drinks.value = Array.from(
    new Set(all_drinks.value.map((drink) => drink?.name))
  ).map((drink) => {
    const thisDrinks = all_drinks.value?.filter((x) => x?.name === drink);
    const category = thisDrinks[0].food_category;
    const show_addons = thisDrinks.every((drink) => drink?.show_addons);
    const available = thisDrinks.every((drink) => drink?.available);
    return {
      name: drink,
      category,
      show_addons,
      available,
    };
  });
  // console.log(all_drinks.value, unique_drinks.value);
});

const isOver5 = (category) => {
  const drinksInCate = unique_drinks.value.filter(
    (item) => item?.category == category
  );
  return drinksInCate?.filter(drink => drink?.show_addons).length >= 5
};

const onChange = (state, name) => {
  drinksToChange.value[name] = {
    name,
    state,
  };
};

const saveChanges = async () => {
  try {
    loadingScreen.commit("openLoadingScreen", { loading: true });
    const changes = [];
    // console.log(drinksToChange.value);
    for (const name in drinksToChange.value) {
      const drinkToChange = drinksToChange.value[name];
      const anyDrinkWithName = all_drinks.value.find(
        (drink) => drink?.name == drinkToChange?.name
      );
      const occurances = getAllOccurancesOfItem(
        meals.state.value,
        anyDrinkWithName
      );
      const show_addonsPaths = occurances.occured_in.map(
        (occurance) => occurance.show_addonsPath
      );
      show_addonsPaths.forEach((path) =>
        changes.push({ path, value: drinkToChange.state })
      );
    }
    console.log(changes);

    await multipleUpdates({ updates: changes });
    toast.success("Successful!");
    drinksToChange.value = {};
  } catch (error) {
    console.log(error);
  } finally {
    loadingScreen.commit("openLoadingScreen", { loading: false });
  }
};

//   watchEffect(async () => {
//     meals.dispatch("updateMeals");
//     mealsWithOptionsOrder.value = meals.state.value.filter(
//       (meal) => meal?.options_order?.length
//     );
//     mealsWithOptionsOrder.value.forEach((meal) => {
//       option_orders.value[meal.name] = meal.option_orders;
//     });
//     console.log(option_orders.value);
//   });
//   const editOptionItems = (meal, option) => {
//     // console.log(meal, option);
//     const option_items = meal?.options[option];
//     option_meals_to_reorder.value = { option_items, meal, option };
//   };

//   const saveNewPosition = async () => {
//     //   console.log(current_.value);
//     try {
//       await set(
//         dbRef(
//           getDatabase(),
//           `/restaurants/${current_.value.restaurant_name}/food/${current_.value.food_category}/${current_.value.index_in_firebase}/options_order`
//         ),
//         current_rearrange.value
//       );
//       toast.success("Suucessful");
//       current_.value = "";
//       current_rearrange.value = "";
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const saveOptionMealsPosition = async () => {
//     try {
//       const value = option_meals_to_reorder.value.option_items;
//       const option = option_meals_to_reorder.value.option;
//       const meal = option_meals_to_reorder.value.meal;
//       const path = `/restaurants/${meal?.restaurant_name}/food/${meal?.food_category}/${meal?.index_in_firebase}/options/${option}`;

//       console.log(
//         path,
//         value?.map((item) => item?.name)
//       );
//       await set(dbRef(getDatabase(), path), value);
//       toast.success("Successful");
//       option_meals_to_reorder.value = {}
//     } catch (error) {
//       console.log(error);
//       option_meals_to_reorder.value = {}
//     }
//   };
</script>
      
      <style lang="scss" scoped>
</style>
<template>
  <layout :hasTopNav="false">
    <v-dialog v-model="openDeleteModal">
      <div class="bg-white px-3 py-2 rounded w-[fit-content] mx-auto">
        <MealModal
          title="Delete Promo?"
          :content="'Are you certain that you want to delete this promo?'"
          :rightClick="() => deletePromoCode()"
          :oncancel="() => (openDeleteModal = false)"
          firstBtn="No, Cancel"
          secondBtn="YES, Confirm"
          :closeBtn="true"
          :tickBtn="false"
        />
      </div>
    </v-dialog>
    <div class="w-full h-[100%] px-5 promo-code">
      <top-nav :arrowback="true" :searchBar="false" />

      <div class="flex w-full items-center justify-between">
        <h1>
          <Text :size="27" :weight="700">
            {{ `${promo_code_id ? "Edit" : "Create"}` }} Promo Code</Text
          >
          <!-- <span class="capitalize">{{ selectId }}</span> -->
        </h1>
        <end-promo :promo_code_id="promo_code_id" v-if="promo_code_id">
        </end-promo>
      </div>
      <form @submit.prevent="submit">
        <div class="w-100 bg-white p-3 rounded">
          <label for="">
            <Text :size="17" :weight="500">Promo Code</Text>
          </label>
          <input
            type="text"
            required
            min="2"
            max="15"
            @input="validatePromoCode"
            v-model="formValues.code"
            class="ue-input-field border"
          />
          <Text class="text-danger"> {{ error.promo_code }}</Text>

          <div class="flex gap-3 mt-3 w-100 max-w-100 items-center">
            <div class="fixed-bonus p-0 border">
              <div
                class="label"
                @click="() => (formValues.type = 'bonus')"
                :class="formValues.type == 'bonus' ? 'selected' : ''"
              >
                Percentage
              </div>
              <div
                class="label"
                @click="() => (formValues.type = 'fixed')"
                :class="formValues.type == 'fixed' ? 'selected' : ''"
              >
                Fixed Amount
              </div>
            </div>
            <div class="w-100">
              <div class="ue-input-field border flex items-center">
                <input
                  type="number"
                  required
                  :max="formValues.type == 'fixed' ? Infinity : 100"
                  v-model="formValues.amount"
                  class="border-none outline-none w-100"
                  @input="validatePromoDiscount"
                />
                <span class="">
                  {{ formValues.type == "fixed" ? "&#8358;" : "%" }}</span
                >
              </div>
              <Text class="text-danger"> {{ error.amount }}</Text>
            </div>
          </div>
        </div>

        <div class="w-100 bg-white p-3 rounded">
          <Text :size="17" :weight="500">Applies to</Text>

          <Text class="flex gap-3" :size="17" :weight="500">
            <input
              type="radio"
              name="for"
              id="all"
              value="All"
              :checked="formValues.for == 'All'"
              v-model="formValues.for"
            />
            <label for="all">All </label>
          </Text>
          <Text class="flex gap-3" :size="17" :weight="500">
            <input
              type="radio"
              name="for"
              id="res"
              @input="change_list"
              value="restaurants"
              :checked="formValues.for == 'restaurants'"
              v-model="formValues.for"
            />
            <label for="res">Specific Restaurants </label>
          </Text>
          <Text class="flex gap-3" :size="17" :weight="500">
            <input
              type="radio"
              name="for"
              value="meals"
              @input="change_list"
              id="meals"
              :checked="formValues.for == 'meals'"
              v-model="formValues.for"
            />
            <label for="meals"> Specific Meals </label>
          </Text>

          <ue-select
            class="ue-input-field ue-select"
            :options="list"
            :label="'display_name'||'name'"
            required
            multiple
            :disabled="formValues.for === 'All'"
            v-model="formValues.items"
            placeholder="Select Type"
          >
            <template #option="option">
              <span :key="option?.display_name || option.name"
                >{{ option?.display_name || option.name }}
              </span>
            </template>
          </ue-select>
        </div>

        <div class="w-100 bg-white p-3 rounded">
          <label for="">
            <Text :size="17" :weight="500">Minimum Purchase Amount</Text>
          </label>
          <div class="ue-input-field border flex items-center">
            <input
              min="0"
              type="number"
              required
              v-model="formValues.minimum_purchase_amount"
              class="border-none outline-none w-100"
            />
            &#8358;
          </div>
          <!-- <input
            type="text"
            class="ue-input-field border"
          /> -->
        </div>

        <div class="w-100 bg-white p-3 rounded">
          <label for="">
            <Text :size="17" :weight="500">Maximum Discount Uses</Text>
          </label>

          <div class="flex gap-3">
            <input
              type="radio"
              name="limit"
              value="infinite"
              id="infinite"
              v-model="formValues.limit_type"
              @change="() => (formValues.limit = 'infinite')"
            />
            <label for="infinite"> Infinite </label>
          </div>
          <div class="flex gap-3">
            <input
              type="radio"
              name="limit"
              value="discount number"
              id="all_per"
              min="1"
              v-model="formValues.limit_type"
              @change="() => (formValues.limit = 1)"
            />

            <label for="all_per">
              Limit number of times this discount can be used in total
            </label>
            <input
              :type="typeof limit === 'number' ? 'number' : 'text'"
              placeholder="limit.."
              required
              v-model="formValues.limit"
              class="ue-input-field !w-[10%] border !px-2 !h-[20px] !p-0"
              :hidden="formValues.limit_type != 'discount number'"
            />
            <!-- @change="() => (formValues.limit = 1)" -->

            <!-- <input type="check" v-model="isinfinite"/> -->
          </div>
          <div class="flex gap-3">
            <input
              type="radio"
              name="limit"
              value="one per customer"
              id="one_per"
              v-model="formValues.limit_type"
            />
            <label for="one_per"> Limit to one per user </label>
          </div>
        </div>

        <div class="w-100 bg-white p-3 rounded">
          <label for="">
            <Text :size="17" :weight="500">Active Dates</Text>
          </label>
          <div class="flex gap-3">
            <div class="w-25">
              <Text :size="17" :weight="500"> State Date </Text>
              <input
                type="date"
                required
                v-model="formValues.start_date"
                class="ue-input-field border"
              />
            </div>
            <div class="w-25">
              <Text :size="17" :weight="500"> State Time </Text>
              <input
                type="time"
                required
                v-model="formValues.start_time"
                class="ue-input-field border"
              />
            </div>
          </div>
          <label class="block my-3">
            <input type="checkbox" v-model="formValues.hasEnd" /> Set End Date
          </label>
          <div class="flex gap-3" v-if="formValues.hasEnd">
            <div class="w-25">
              <Text :size="17" :weight="500"> End Date </Text>
              <input
                type="date"
                required
                v-model="formValues.end_date"
                class="ue-input-field border"
              />
            </div>
            <div class="w-25">
              <Text :size="17" :weight="500"> End Time </Text>
              <input
                type="time"
                required
                v-model="formValues.end_time"
                class="ue-input-field border"
              />
            </div>
          </div>
        </div>
        <!-- {{ loading }} -->
        <ue-button :variant="'lg'" :disabled="loading" class="mx-auto mb-5">
          {{
            loading
              ? "Loading..."
              : `${promo_code_id ? "Edit" : "Create"} Promo Code`
          }}
        </ue-button>
        <ue-button
          :variant="'lg'"
          type="button"
          :disabled="loading"
          @click="() => (openDeleteModal = true)"
          class="mx-auto mb-5 bg-danger text-white"
        >
          Delete Promo Code
        </ue-button>
      </form>

      <div
        v-if="promoCodeOrders?.length"
        class="flex flex-col w-100 border-t pt-2 mb-4 pb-4"
      >
        <div v-for="order in promoCodeOrders" :key="order.id">
          <Text :weight="600" :size="14" class="flex gap-2">
            <customer-name :customer="order?.customer" :id_key="'id'" />
            <OrderID :order="order" />
          </Text>
        </div>
      </div>
    </div>
  </layout>
</template>

<script setup>
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import CustomerName from "@/components/CustomerName.vue";
import layout from "@/partials/Layout/layout.vue";
import { meals as mealList } from "@/views/store/meals";
import {
  get,
  ref as dbRef,
  getDatabase,
  set,
  push,
  remove,
} from "firebase/database";
import { onMounted, ref, watchEffect } from "vue";
import UeButton from "@/components/UeButton.vue";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import EndPromo from "./EndPromo.vue";
import MealModal from "@/components/MealModal.vue";
import OrderID from "@/components/OrderID.vue";
// import Text from "@/components/Text.vue";
const formValues = ref({
  code: "",
  amount: "",
  type: "fixed",
  limit: "infinite",
  limit_type: "infinite",
  items: "",
  for: "All",
  start_date: "",
  start_time: "",
  end_date: "",
  end_time: "",
  hasEnd: false,
  ended: false,
});
const list = ref([]);
const toast = useToast({ position: "top-right" });
const loading = ref(false);
// const formValues.value.hasEnd = ref(false);
const meals = mealList.state.value;
const router = useRouter();
const restaurants = ref([]);
const error = ref({});
const openDeleteModal = ref(false);
const promoCodeUsers = ref([]);
const promoCodeOrders = ref([]);
// const getUserDetails =

const ended = ref(false);

const {
  params: { promo_code_id },
} = useRoute();

watchEffect(async () => {
  const res = await get(dbRef(getDatabase(), "restaurants"));
  if (res.val()) restaurants.value = Object.keys(res.val());
});

onMounted(() => {
  mealList.dispatch("updateMeals");
});

watchEffect(() => {
  formValues.value.for;

  list.value = restaurants.value;
  if (formValues.value.for == "meals") {
    list.value = meals.map((meal) => ({
      ...meal,
      display_name: `${meal?.name} - ${meal?.restaurant_name}`,
      id: `${meal?.name}-${meal?.restaurant_name}-${meal?.id}`, //for unique ness
    })).filter(meal => !formValues.value.items.map(item => item.id).includes(meal.id));
  }

  if (formValues.value.for == "All") formValues.value.items = restaurants.value;
  console.log(formValues.value.items);
});

const change_list = (e) => {
  formValues.value.items = [];
};

watchEffect(async () => {
  if (promo_code_id) {
    const _ref = dbRef(getDatabase(), `promo_codes/${promo_code_id}`);
    const res = await get(_ref);
    if (res.val()) {
      formValues.value = res.val();
      console.log(res.val());

      ended.value = res.val()?.ended;
      // const users = res.val()?.users || [];
      // users.forEach(async (id, i) => {
      //   const response = await get(dbRef(getDatabase(), `users/${id}`));
      //   if (response.val()) promoCodeUsers.value[i] = response.val();
      // });

      const orderids = res.val()?.orderid || [];
      console.log(orderids);
      orderids.forEach(async (id, i) => {
        const response = await get(dbRef(getDatabase(), `orders/${id}`));
        if (response.val()) promoCodeOrders.value[i] = response.val();
      });
    }
  }
});

watchEffect(() => {
  formValues.value.hasEnd;
  if (!formValues.value.hasEnd) {
    formValues.value.end_date = "";
    formValues.value.end_time = "";
  }
});
const datesValid = () => {
  if (formValues.value.hasEnd) {
    return (
      new Date(formValues.value.end_date).getTime() >
      new Date(formValues.value.start_date).getTime()
    );
  }
  return true;
};

// console.log(promo_code_id);

const validatePromoCode = (e) => {
  formValues.value.code = formValues.value.code
    .replaceAll(" ", "")
    .replaceAll(/[^a-zA-Z0-9 ]/g, "");

  const value = e.target.value;
  if (value.length < 3) error.value.promo_code = "Minimum 3 characters";
  if (value.length > 15) error.value.promo_code = "Maximum 3 characters";
  if (value.length > 2 && value.length < 15) error.value.promo_code = "";
};

const validatePromoDiscount = () => {
  formValues.value.amount = Math.abs(formValues.value.amount);
  const value = formValues.value.amount;
  if (formValues.value.type == "bonus") {
    if (value > 101) {
      formValues.value.amount = 100;
      return "Percentage amount cannot be greater than 100% ";
    } else {
      return "";
    }
  }
  return "";
};

watchEffect(() => {
  formValues.value.type;
  formValues.value.amount;
  error.value.amount = validatePromoDiscount();
});

const submit = async () => {
  // const notExists = (await get(query(promo_code_id)))?.val();
  // if (notExists?.code) {
  //   toast.info("This code is taken");
  // } else {

  const query = (path = "") => dbRef(getDatabase(), `promo_codes/${path}`);
  if (formValues.value.code?.length < 3 || formValues.value.code?.length > 15) {
    toast.info("Code has to be between 3 and 15 alphanumeric characters");
  } else {
    if (!datesValid()) {
      toast.info(" End date has to be after start date");
    } else {
      loading.value = true;
      try {
        if (promo_code_id) {
          //edit
          await set(query(`${promo_code_id}/`), formValues.value);
          toast.success("Promo edited Successfully");
        } else {
          //create
          const res = await push(query(""), formValues.value);
          await set(query(`${res.key}/id`), res.key);
          toast.success("Promo created Successfully");
        }

        router.push("/operator/promo-codes");
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    }
  }
};

const deletePromoCode = async () => {
  // const get
  try {
    await remove(dbRef(getDatabase(), `promo_codes/${promo_code_id}`));
    toast.success("Promo code deleted");
    router.push("/operator/promo-codes");
  } catch (error) {
    console.log(error);
  }
};
</script>

<style lang="scss">
.promo-code {
  form {
    label {
      font-size: 17px;
      // text-transform: capitalize;
    }
    display: flex;
    flex-direction: column;
    gap: 12px;
    input,
    .vs__search,
    .vs__selected {
      color: rgba(0, 0, 0, 1);
      font-family: Work Sans;
      font-size: 17px !important;
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-family: Work Sans;
        font-size: 17px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .fixed-bonus {
      border-radius: 12px;
      width: fit-content;
      align-items: center;
      height: 100%;
      border: 1px solid black;

      display: flex;
      padding: 0;
      .label {
        padding: 5px 12px;
        align-items: center;
        cursor: pointer;
        font-size: 17px;
        height: 100%;
        white-space: nowrap;
        &:nth-child(1) {
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
        }
        &:nth-child(2) {
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      }

      .selected {
        background: grey;
        //   font-weight: 600;
      }
    }
  }
}
</style>
<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>
  <layout :hasTopNav="false" :hasSearch="false">
    <div class="mainContent p-[40px]">
      <section class="block">
        <Text size="27" weight="600">Dashboard</Text>
      </section>
      <section class="flex justify-end">
        <time-drop-down :getDate="(e) => (timeFrame = e)" />
      </section>
      <section class="metricsBar w-full">
        <div v-for="metric in metricList" :key="metric.title" class="metric">
          <div class="metric-info">
            <Text :size="32" :weight="600">{{ metric.totalNumber }}</Text>
            <Text :size="15" :weight="600">{{ metric.title }}</Text>
          </div>
          <div class="metric-icon">
            <i :class="metric.iconClass"></i>
            <div class="w-[50px] h-[50px] border rounded-full gradienticon">
              <span class="material-symbols-outlined text-[white] sidebar-icon">
                {{ metric.icon }}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section class="p-2 bg-white w-[fit-content] rounded px-3">
        <Text :size="20" :weight="600" class="mb-2">Top rider</Text>
        <div
          class="flex gap-3 rounded border w-[fit-content] my-1 items-center p-2"
          v-for="rider in topRider"
          :key="rider"
        >
          <Text :size="14" :weight="600"> {{ rider.name }}</Text>
          <Text :size="14" :weight="600">{{ rider.count }}</Text>
        </div>

        <div v-if="!topRider.length">No orders yet</div>
      </section>
      <section class="cus_top">
        <div class="graph1"><AverageDeliveryTime /></div>
        <div class="top_selling"><top-locations /></div>
      </section>
    </div>
  </layout>
</template>
        
      <script>
import { ref, watchEffect } from "vue";
import getUser from "@/composables/getUser";
import SideBar from "@/components/SideBar.vue";
import AdminUser from "@/components/AdminUser.vue";
import Text from "@/components/Text.vue";
import Layout from "@/partials/Layout/layout.vue";
//   import OrderSummary from "./OrderSummary.vue";
//   import Revenue from "./Revenue.vue";
//   import CutomerMap from "./OrderAnalytics.vue";
//   import TopSelling from "./TopSelling.vue";
// import RecentOrdersVue
//   import RecentOrders from "./RecentOrders.vue";
import { store } from "@/views/store/store";
import { formatPrice, getOrdersByTimeFrame, sum } from "@/util";
import TopLocations from "./TopLocations.vue";
import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue";
import { httpsCallable } from "firebase/functions";
import { projectFunctions } from "@/firebase/config";
import AverageDeliveryTime from "./AverageDeliveryTime.vue";
import dayjs from "dayjs";
export default {
  components: {
    SideBar,
    AdminUser,
    Layout,
    Text,
    TopLocations,
    AverageDeliveryTime,
    TimeDropDown,
  },

  setup() {
    //to get user's display name for icon
    const { user } = getUser();
    // const today =
    const metricList = ref([]);
    const timeFrame = ref();
    const ridersDeliveredOrderCount = ref(null);
    const topRider = ref({});

    // const testGetOrders = async () => {
    //   const getOrders = httpsCallable(projectFunctions, "getOrders");
    //   await getOrders();
    // };
    const calculateTimeDifference = (start, end, compare, ID) => {
      const toDate = (date) => dayjs(date?.replaceAll('"', ""));
      const res = Math.abs(toDate(start).diff(toDate(end), "minutes"));
      return res <= compare;
    }

    watchEffect(async () => {
      const res = await getOrdersByTimeFrame(
        timeFrame.value?.start,
        timeFrame.value?.end
      );
      let todayDeliveryOrders = res?.filter(
        (x) => x.locationType == "Delivery"
      );
      let completedDeliveries = 0,
        in_progress = 0,
        online_riders = 0,
        delivery_in_25_mins_count = 0,
        onlyDeliveredOrders = [],
        DeliveryRevenue = 0,
        logistics_on_time = 0,
        logistics_on_time_percent = 0,
        logistics_on_time_extended = 0,
        logistics_on_time_extended_percent = 0

      if (todayDeliveryOrders.length) {
        completedDeliveries = todayDeliveryOrders.filter(
          (x) => x.status?.Delivered?.state
        ).length;

        in_progress = todayDeliveryOrders.filter(
          (x) => !x.status?.Delivered?.state && x.status?.Dispatched?.state
        ).length;

        onlyDeliveredOrders = todayDeliveryOrders.filter(
          (order) => order.status.Delivered.state && order.status.Delivered.time
        );
        const DeliveredOrdersRegular = todayDeliveryOrders?.filter(
          (order) => order?.delivery_range !== "extended"
        );
        const DeliveredOrdersExtended = todayDeliveryOrders?.filter(
          (order) => order?.delivery_range === "extended"
        );
        logistics_on_time = DeliveredOrdersRegular?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Dispatched?.time,
            order?.status?.Arrived?.time,
            30,
            order?.id
          )
        )?.length;
        logistics_on_time_percent = `${Math.round(
          (logistics_on_time / DeliveredOrdersRegular.length) * 100
        )}% (${logistics_on_time})`;

        logistics_on_time_extended = DeliveredOrdersExtended?.filter((order) =>
          calculateTimeDifference(
            order?.status?.Dispatched?.time,
            order?.status?.Arrived?.time,
            60,
            order?.id
          )
        )?.length;
        logistics_on_time_extended_percent =`${ Math.round(
          (logistics_on_time_extended / (DeliveredOrdersExtended.length || 1)) *
            100
        )}% (${logistics_on_time_extended})`;

        
        delivery_in_25_mins_count = onlyDeliveredOrders.filter((order) => {
          let dispatched_date = new Date(
            order?.status?.Dispatched?.time.replaceAll('"', "")
          ).getTime();
          let delivered_date = new Date(
            order?.status?.Delivered?.time.replaceAll('"', "")
          ).getTime();
          const time_from_dispatch_delivered = Math.abs(
            dispatched_date - delivered_date
          );
          console.log(time_from_dispatch_delivered, 1500000);
          let minutes_25_milli = 1500000;
          return time_from_dispatch_delivered <= minutes_25_milli;
        }).length;

        online_riders = store.state.riders.filter(
          (x) => x.status == "online"
        ).length;

        const topRiderSet = new Set();
        todayDeliveryOrders
          ?.filter((order) => order?.courier)
          ?.forEach((order) => {
            const courier = order?.courier;
            const riderId = courier?.id;
            if (order?.status?.Delivered?.state) {
              if (topRiderSet.has(riderId)) {
                ridersDeliveredOrderCount.value[riderId].count++;
              } else {
                topRiderSet.add(riderId);
                ridersDeliveredOrderCount.value = {
                  ...ridersDeliveredOrderCount.value,
                  [riderId]: { ...courier, count: 1 },
                };
              }
            }
          });

        const ridersDeliveredOrderCountArray = Object.values(
          ridersDeliveredOrderCount.value || {}
        );
        topRider.value = ridersDeliveredOrderCountArray
          ?.sort((a, b) => b.count - a.count)
          ?.filter((rider) => rider?.name);

        DeliveryRevenue = sum(
          todayDeliveryOrders?.map((order) => order?.delivery || 1500)
        );
      }
      metricList.value = [
        {
          title: "Completed delivery",
          totalNumber: completedDeliveries,
          icon: "task",
        },
        {
          title: "In progress",
          totalNumber: in_progress,
          icon: "pending",
        },
        {
          title: "On-time delivery",
          totalNumber: onlyDeliveredOrders.length
            ? `${Math.round(
                (delivery_in_25_mins_count / onlyDeliveredOrders.length) * 100
              )}%`
            : `0%`,
          icon: "timer ",
        },
        {
          title: "Logistics On time [Regular] ", /*(%)*/
          totalNumber: logistics_on_time_percent,
          icon: "electric_moped",
          extra: "30mins",
        },
        {
          title: "Logistics On time [Extended] ", /*(%)*/
          totalNumber: logistics_on_time_extended_percent,
          icon: "electric_moped",
          extra: "60mins",
        },
        {
          title: "Online Riders",
          totalNumber: online_riders,
          icon: "account_circle",
        },
        {
          title: "Delivery Revenue",
          totalNumber: formatPrice(Math.round(DeliveryRevenue)),
          icon: "account_circle",
        },
      ];
    });
    return {
      metricList,
      formatPrice,
      timeFrame,
      topRider,
    };
  },
};
</script>
        
        <style scoped lang="scss">
.mainContent {
  // background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 35px;
  .metricsBar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2%;
    margin: auto;
  }
  .metric {
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 10px;
    border-radius: 14px;
    background: #fff;
    align-items: center;
  }
  .metric-info {
    text-align: left;
    padding: 0 12px;
  }
  .sum_rev {
    display: flex;
    // gap: 32px;
    justify-content: space-between;
    width: 100%;
    & > div {
      width: calc(50% - 16px);
      height: 453px;
      flex-shrink: 0;
      border-radius: 16px;
      background: #fff;
    }
  }
  .cus_top {
    display: flex;
    justify-content: space-between;
    & > div {
      border-radius: 16px;
      background: #fff;
      height: 458px;
      flex-shrink: 0;
    }
    .graph1 {
      width: calc(50% - 8px);
    }
    .top_selling {
      width: calc(50% - 8px);
    }
  }

  .recent_orders {
    width: 100%;
    height: 642px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #fff;
  }
  .gradienticon {
    align-items: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(253deg, #ff9103 29.53%, #f60100 103.89%);
  }
}
</style>
        
export const extra_permissions_keys = {
    vip: 'make_customer_vip',
    deduct_wallet: 'deduct_customer_wallet',
    top_wallet: 'top_customer_wallet',
    edit_customer: 'edit_customer',
    start_trip: 'start_trip',
    arrive_trip: 'arrive_trip',
    deliver: 'deliver',
    complete_meal_preparation: 'complete_meal_preparation',
    restore_cancelled_order: 'restore_cancelled_order',
    cancel_single_item: "cancel_single_item",
    override_confirmation_modal: "override_confirmation_modal",
    edit_meal_price: "edit_meal_price",
    edit_meal_stock: "edit_meal_stock",
    cancel_order_after_dispatch: "cancel_order_after_dispatch",
    reverse_assembly: "reverse_assembly",
    notify_on_bug: "notify_on_bug",
}
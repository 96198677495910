<template>
  <div
    v-if="mbpBalanceIssue"
    class="bg-white rounded p-2 w-full font-bold flex gap-3"
  >
    <div>
      Correct MBP: {{ mbpBalanceIssue?.correctMBP }} <br />
      <ue-button
        v-if="!basketWithPoint.length && !processingOrdersWithPoints.length"
        :disabled="loading"
        @click="fix"
      >
        {{ loading ? "Loading" : "Fix" }}
      </ue-button>
    </div>
    <div>
      <Text color="red" v-if="basketWithPoint.length">
        Some items in the customer's basket has meal bot point
      </Text>

      <Text color="red" v-if="processingOrdersWithPoints.length">
        Some items in the customer's processing order(s) has meal bot point
      </Text>
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";

import { get, set, ref as dbRef, getDatabase, remove } from "firebase/database";
import { ref } from "vue";
import { watchEffect } from "vue";
import { useToast } from "vue-toast-notification";

const props = defineProps({
  customer: Object,
  orders: Array,
});
const mbpBalanceIssue = ref(null);
const loading = ref(false);
const processingOrdersWithPoints = ref([]);
const basketWithPoint = ref([]);

watchEffect(async () => {
  // console.log(props.customer)
  mbpBalanceIssue.value = (
    await get(
      dbRef(getDatabase(), `/unbalance_mbp_account/${props.customer?.uid}`)
    )
  ).val();

  const basket = props.customer?.basket
    ? JSON.parse(props.customer?.basket || "")
    : [];
  basketWithPoint.value = basket?.filter((basket) =>
    basket.items?.some((item) => item.point)
  );

  const orders = props.orders;
  processingOrdersWithPoints.value = orders
    .filter((order) => {
      const isDelivery = order?.locationType === "Delivery";
      if (isDelivery) return !order?.status?.Dispatched?.state;
      else return !order?.status?.Pickup?.state;
    })
    .filter((order) => {
      const items = order?.items;
      return items?.some((item) => item.point);
    });

  console.log(processingOrdersWithPoints.value);
});
const fix = async () => {
  try {
    loading.value = true;
    await set(
      dbRef(getDatabase(), `users/${props.customer?.uid}/mealPoint/point`),
      mbpBalanceIssue.value.correctMBP
    );
    await remove(
      dbRef(getDatabase(), `/unbalance_mbp_account/${props.customer?.uid}`)
    );
    mbpBalanceIssue.value = null;
    useToast({ position: "top-right" }).success("MBP Fixed!");
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="scss" scoped>
</style>